import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import Stars from '~components/stars';
import Carousel from '~components/carousel';
import feedbackPicture from '~public/images/feedback-company.png';

import Testimonial from './testimonial';
import useIntlTestimonials from './useIntlTestimonials';

import './style.scss';

const Testimonials = memo(() => {
  const intl = useIntl();

  const testimonials = useIntlTestimonials();
  return (
    <div className="c-testimonials">
      <div className="c-testimonials__header">
        <a
          className="c-testimonials__fc-link"
          href="https://www.feedbackcompany.com/nl-nl/reviews/juan-pescador/"
          rel="noopener noreferrer nofollow"
          aria-label="Feedback Company"
          target="_blank"
        >
          <img
            src={feedbackPicture}
            alt="Feedback Company"
            className="c-testimonials__fc-logo"
            width="106"
            height="30"
            loading="lazy"
          />
        </a>
        <div className="c-testimonials__fc-stars">
        <Stars
          value={Number(
            intl.formatMessage({
              id: 'containers.testimonials.feedback-company.stars-value',
              defaultMessage: '4.5',
            })
          )}
        />
        <div className="c-testimonials__fc-score">
          {intl.formatMessage({
            id: 'containers.testimonials.feedback-company.score',
            defaultMessage: '6.9/10',
          })}
        </div>
        </div>

      </div>
      <Carousel className="c-testimonials__carousel">
        {testimonials.map((testimonial) => (
          <Testimonial
            key={testimonial.name}
            name={testimonial.name}
            date={testimonial.date}
            score={testimonial.score}
            stars={testimonial.stars}
            content={testimonial.content}
          />
        ))}
      </Carousel>
        <a
          className="c-testimonials__button"
          href="https://www.feedbackcompany.com/nl-nl/reviews/juan-pescador/"
          rel="noopener noreferrer nofollow"
          aria-label="Feedback Company"
          target="_blank"
        >
          {intl.formatMessage({
            id: 'containers.testimonials.button',
            defaultMessage: 'Show all reviews',
          })}
        </a>
    </div>
  );
});

export default Testimonials;
