import { useContext } from 'react';

import { FavoriteContext } from '~contexts/favorite';

export const useFavorite = () => {
  const favoriteContext = useContext(FavoriteContext);

  // if `undefined`, throw an error
  if (favoriteContext === undefined) {
    throw new Error('Favorite context  was used outside of its Provider');
  }

  return { ...favoriteContext };
};
