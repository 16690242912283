import React from 'react';
import { Route, useLocation } from 'react-router-dom';

const PublicRoute = ({ path, children, ...rest }) => {
  const location = useLocation();

  return (
    <Route path={path} location={location} {...rest}>
      {children}
    </Route>
  );
};

export default PublicRoute;
