import React, { useState } from 'react';
import { isFunction } from 'lodash';

import Modal from '~components/modal';
import Button from '~components/button';

const Prompt = ({
  title,
  content,
  ok = 'OK',
  cancel,
  isOpened = false,
  callback,
  onDismiss = () => {},
  onSubmit = () => {},
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (callback && isFunction(callback)) {
      setIsLoading(true);
      await callback();
    }
    onSubmit();
  };

  return (
    <Modal.Wrapper
      isOpened={isOpened}
      onClose={onDismiss}
      closableOnOverflowClick={false}
      data-cy="prompt"
    >
      {title && (
        <Modal.Header>
          <h3 className="l-headline--tertiary">{title}</h3>
        </Modal.Header>
      )}
      {content && <Modal.Content>{content}</Modal.Content>}
      <Modal.Footer>
        <div className="c-modal__buttons--flex-end">
          {cancel && (
            <Button
              className="c-modal__button c-btn--text"
              data-cy="button-cancel"
              onClick={onDismiss}
            >
              {cancel}
            </Button>
          )}
          <Button
            className="c-modal__button c-btn"
            data-cy="button-confirm"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            {ok}
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Wrapper>
  );
};

export default Prompt;
