import React from 'react';
import cx from 'classnames';

import './style.scss';

const SkeletonSimple = ({ children, theme = 'primary', className }) => {
  return (
    <div
      className={cx('c-skeleton-simple', {
        'c-skeleton-simple--primary': theme === 'primary',
        'c-skeleton-simple--secondary': theme === 'secondary',
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};

export default SkeletonSimple;
