import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { isEmpty, isArray } from 'lodash';

import { getChildrenByType } from '~helpers/children';
import { useStateFromProp } from '~hooks/common';

import Dropdown from '~components/dropdown/';
import Loader from '~components/loader';
import { Input } from '~components/forms/input';

import Empty from './empty';
import Suggestion from './suggestion';
import Content from './content';
import Toggle from './toggle';

import { ValueContext } from './context';

import './style.scss';

const ENTER_KEY = 13;
const ESC_KEY = 27;
const MIN_CHARACTERS = 3;

const Container = ({
  placeholder,
  className,
  optionsClassName,
  initialVisible = false,
  initialValue = '',
  initialSelection = null,
  searchTreshold = MIN_CHARACTERS,
  isLoading = false,
  disabled = false,
  errors,
  isInvalid,
  children,
  name,
  inputRef,
  placement = 'bottom-start',
  mask = null,
  icon,
  initialTouch = false,
  onChange = () => {},
  onSubmit = () => {},
  onBlur = () => {},
  onInputClick = () => {},
  ...props
}) => {
  const [value, setValue] = useStateFromProp(props.value, props.setValue);
  const [touch, setTouch] = useState(initialTouch);
  const [visible, setVisible] = useState(initialVisible);
  const [selection, setSelection] = useState(initialSelection);
  const [popperPlacement, setPopperPlacement] = useState(placement);

  const content = getChildrenByType(children, 2, Content);
  const toggleContent = getChildrenByType(children, 2, Toggle);
  const suggestions = getChildrenByType(children, 3, Suggestion);

  const EmptySuggestions = () => {
    return (
      (children && children.filter((child) => child.type === Empty)) || <div className="c-autocomplete__no-suggestions">No suggestions</div>
    );
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (ev) => {
    setTouch(true);
    setValue(ev.target.value);
    setSelection(null);
    onChange(ev.target.value);
  };

  const handleSuggestionClick = (newInputValue, newSuggestion) => {
    setValue(newInputValue);
    setSelection(newSuggestion);
    onSubmit(newSuggestion);
    setVisible(false);
    setTouch(false);
  };

  const handleKeyUp = (key) => {
    if (key.keyCode == ESC_KEY) {
      onBlur();
      setVisible(false);
      setTouch(false);
      return;
    }

    if (key.keyCode !== ENTER_KEY) {
      return;
    }

    if (suggestions && isArray(suggestions) && suggestions.length > 0) {
      setValue(suggestions[0].props.label);
      setSelection(suggestions[0].props.value);

      onSubmit(suggestions[0].props.value);
    } else {
      onBlur();
    }
    setVisible(false);
    setTouch(false);
  };

  const handleInputClick = () => {
    onInputClick();
    if ((value && !isLoading && value.length >= searchTreshold) || searchTreshold === 0) {
      setTouch(true);
      if(!visible) {
        setTimeout(() => {
          setVisible(true);
        }, 0);
      }
    }
  };


  useEffect(() => {
    if (touch && !selection && value && value.length >= searchTreshold) {
      setVisible(true);
    }
  }, [value]);

  const modifiers = useMemo(
    () => [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 0],
        },
      },
      {
        name: 'sameWidth',
        enabled: true,
        fn: ({ state }) => {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
    ],
    []
  );

  const handleVisibleChange = (v) => {
    setVisible(true);
  };

  const handleBlur = () => {
    setVisible(false);
    onBlur();
  }

  return (
    <ValueContext.Provider value={{ onSuggestionChange: handleSuggestionClick, value }}>
      <Dropdown.Container
        className={cx('c-autocomplete', `c-autocomplete--placement-${popperPlacement}`, {
          'c-autocomplete--loading': isLoading,
          'c-autocomplete--placeholder': value === null || value === undefined,
          'c-autocomplete--opened': visible && value && value.length >= searchTreshold,
          'c-autocomplete--invalid': isInvalid && !disabled,
          [className]: !!className,
        })}
        placement={placement}
        modifiers={modifiers}
        visible={visible && value && value.length >= searchTreshold}
        optionsClassName={cx('c-autocomplete__options', {
          [optionsClassName]: !!optionsClassName,
        })}
        onVisibleChange={handleVisibleChange}
        onBlur={handleBlur}
        hideOnBlur={false}
        onPopperMove={(popper) => {
          setPopperPlacement(popper && popper['data-popper-placement'] ? popper['data-popper-placement'] : placement);
        }}
      >
        <Dropdown.Toggle>
          <Input
            type="text"
            autoComplete="off"
            name={name}
            className="c-autocomplete__field"
            placeholder={placeholder}
            value={value || ''}
            disabled={disabled}
            submitOnEnter={false}
            errors={errors}
            isInvalid={isInvalid}
            inputRef={inputRef}
            onFocus={onInputClick}
            // onBlur={handleBlur}
            mask={mask}
            icon={icon}
            onClick={handleInputClick}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
          {touch && isLoading && <Loader className="c-autocomplete__loader" />}
          {toggleContent}
        </Dropdown.Toggle>
        <Dropdown.Content>
          {((value && value.length >= searchTreshold) || searchTreshold === 0) && !isLoading && touch ? (
            !isEmpty(suggestions) ? (
              content
            ) : (
              <EmptySuggestions />
            )
          ) : null}
        </Dropdown.Content>
      </Dropdown.Container>
      <div className="c-autocomplete__overlay" onClick={() => setVisible(false)} />
    </ValueContext.Provider>
  );
};

export default Container;
