import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import './style.scss';

const Crumb = ({ children, className }) => {
  return (
    <div
      className={cx('c-breadcrumb__item', {
        [className]: !!className,
      })}
      itemProp="itemListElement"
      itemScope
      itemType="http://schema.org/ListItem"
    >
      {children}
    </div>
  );
};

const CrumbLink = ({ to, index, children, className }) => {
  return (
    <>
      <Link
        to={to}
        className={cx({
          [className]: !!className,
        })}
        itemType="https://schema.org/WebPage"
        itemProp="item"
        itemScope
        itemID={to}
      >
        <span itemProp="name">{children}</span>
      </Link>
      <meta itemProp="position" content={index} />
    </>
  );
};

const Wrapper = ({ children, className }) => {
  return (
    <div
      className={cx('c-breadcrumb', {
        [className]: !!className,
      })}
      itemScope
      itemType="http://schema.org/BreadcrumbList"
    >
      {children}
    </div>
  );
};

export default { Wrapper, Crumb, CrumbLink };
