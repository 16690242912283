import React from 'react';
import { renderFirstName } from '~helpers/common';
import Stars from '~components/stars';

const Review = ({ firstName, score, comment }) => {
  const fName = renderFirstName(firstName);

  return (
    <div className="c-review">
      <div className="c-review__name">{fName}</div>
      <div className="c-review__stars">
        <Stars value={score} />
        <div className="c-review__score">{`${score}/5`}</div>
      </div>

      <div className="c-review__comment">{comment || '-'}</div>

      {/* Google structured data */}
      <div itemProp="review" itemType="https://schema.org/Review" itemScope>
        <div itemProp="author" itemType="https://schema.org/Person" itemScope>
          <meta itemProp="name" content={fName} />
        </div>
        <div itemProp="reviewRating" itemType="https://schema.org/Rating" itemScope>
          <meta itemProp="ratingValue" content={score} />
          <meta itemProp="bestRating" content="5" />
        </div>
      </div>
    </div>
  );
};

export default Review;
