export const workerMenuItems = ({ locationId }) => [
  {
    pathname: `/customers${locationId ? `?locationId=${locationId}` : ''}`,
    translation: 'components.header.menu-items.worker.offers',
    defaultMessage: 'Offers',
  },
  {
    pathname: '/chats',
    translation: 'components.header.menu-items.worker.chats',
    defaultMessage: 'Chats',
  },
  {
    pathname: '/favorites',
    translation: 'components.header.menu-items.worker.favorites',
    defaultMessage: 'Favorites',
  },
  {
    pathname: '/account',
    translation: 'components.header.menu-items.worker.profile',
    defaultMessage: 'Profile',
  },
  {
    pathname: '/settings',
    translation: 'components.header.menu-items.worker.settings',
    defaultMessage: 'Settings',
  },
  {
    pathname: '/contact',
    translation: 'components.header.menu-items.worker.contact',
    defaultMessage: 'Contact',
  },
];

export const clientMenuItems = ({ locationId }) => [
  {
    pathname: `/workers${locationId ? `?locationId=${locationId}` : ''}`,
    translation: 'components.header.menu-items.client.offers',
    defaultMessage: 'Offers',
  },
  {
    pathname: '/chats',
    translation: 'components.header.menu-items.client.chats',
    defaultMessage: 'Chats',
  },
  {
    pathname: '/favorites',
    translation: 'components.header.menu-items.client.favorites',
    defaultMessage: 'Favorites',
  },
  {
    pathname: '/account',
    translation: 'components.header.menu-items.client.profile',
    defaultMessage: 'Profile',
  },
  {
    pathname: '/settings',
    translation: 'components.header.menu-items.client.settings',
    defaultMessage: 'Settings',
  },
  {
    pathname: '/contact',
    translation: 'components.header.menu-items.client.contact',
    defaultMessage: 'Contact',
  },
];
