import axios from 'axios';

const axiosProvider = {
  publicAxios: {},
  authAxios: {},
  mockAxios: {},
  isInitialized: false,
};

axiosProvider.initialize = ({ baseURL, mockURL, language = 'en-gb', domain }) => {
  if (process.env.NODE_ENV !== 'development') {
    axiosProvider.publicAxios = axios.create({
      baseURL,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'ICT-Works-Language': language,
        'ICT-Works-Domain': domain,
      },
    });
  } else {
    axiosProvider.publicAxios = axios.create({
      baseURL,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'ICT-Works-Language': language,
        'ICT-Works-Domain': domain,
      },
    });

    axiosProvider.mockAxios = axios.create({
      baseURL: mockURL,
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
  }

  axiosProvider.isInitialized = true;
};

axiosProvider.clear = () => {
  axiosProvider.publicAxios = {};
  axiosProvider.authAxios = {};
  axiosProvider.isInitialized = false;
};

export default axiosProvider;
