import * as yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { debounce, isEmpty } from 'lodash';

import { ASYNC_VALIDATION_TIMEOUT_IN_MS } from '~src/constants';

const validateCorrectPhoneNumber = (value) => {
  return isValidPhoneNumber(value);
};

const validatePhoneNumber = (value, required) => {
  // if (required && (!value.isoCode || !value.number)) {
  if (required && !value) {
    return new yup.ValidationError('components.form.validation.mixed.required');
  }

  if (isEmpty(value)) {
    return true;
  }

  const isValid = validateCorrectPhoneNumber(value);
  if (!isValid) {
    return new yup.ValidationError('components.form.validation.string.phone-number');
  }

  return true;
};

export const validatePhoneNumberDebounced = debounce(validatePhoneNumber, ASYNC_VALIDATION_TIMEOUT_IN_MS);

yup.addMethod(yup.string, 'phoneNumber', function (params = {}) {
  return this.test({
    name: 'is-phone-number-valid',
    exclusive: true,
    test: (value) => validatePhoneNumber(value, params.required),
  });
});

yup.addMethod(yup.string, 'noPhoneInContent', function () {
  return this.test({
    name: 'no-phone-in-content',
    exclusive: true,
    message: 'components.form.validation.string.no-phone-in-content',
    test: (value) => {
      if (!value) {
        return true;
      }

      // remove all white spaces and special characters
      const testValue = value.replace(/[^a-zA-Z0-9]/g, '');
      const phoneRegex = /[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,9}/im;
      return !phoneRegex.test(testValue);
    },
  });
});
