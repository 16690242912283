import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useStateFromProp = (initialValue, onChange) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => setValue(initialValue), [initialValue]);

  return [value, onChange || setValue];
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useDelayedRender = (delay) => {
  const [delayed, setDelayed] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), delay);
    return () => clearTimeout(timeout);
  }, []);
  return (fn) => !delayed && fn();
};

const useCountdown = ({
  date = Date.now(),
  intervalTime = 1000,
  now = Date.now(),
  format = (v) => {
    return v;
  },
}) => {
  const [timeLeft, setTimeLeft] = useState(() => Math.max(date - now, 0));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((current) => {
        if (current <= intervalTime) {
          clearInterval(interval);
          return 0;
        }

        return current - intervalTime;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [intervalTime]);

  return format(timeLeft);
};

const useThrottledEffect = (callback, delay, deps = []) => {
  const lastRan = useRef(Date.now());

  useEffect(() => {
    const handler = setTimeout(() => {
      if (Date.now() - lastRan.current >= delay) {
        callback();
        lastRan.current = Date.now();
      }
    }, delay - (Date.now() - lastRan.current));

    return () => {
      clearTimeout(handler);
    };
  }, [delay, ...deps]);
};

const useDebouncedEffect = (callback, delay, deps = []) => {
  const data = useRef({ firstTime: true });
  useEffect(() => {
    const { firstTime, clearFunc } = data.current;

    if (firstTime) {
      data.current.firstTime = false;
      return;
    }

    const handler = setTimeout(() => {
      if (clearFunc && typeof clearFunc === 'function') {
        clearFunc();
      }
      data.current.clearFunc = callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, ...deps]);
};

// Get URL parameters hook
// Usage:
//
// - current url: http://localhost:8000/#/signin?_k=v9ifuf&__firebase_request_key=blablabla
// const { __firebase_request_key } = useSearchParams('__firebase_request_key');
//
// current url: http://localhost:3000/home?b=value
// const searchParams = useSearchParameters('a', 'b'); // {a: null, b: 'value'}
const useSearchParams = (...parameterNames) => {
  const { search } = useLocation();

  return useMemo(() => {
    // recalculate only when 'search' or arguments changed
    const searchParams = new URLSearchParams(search);
    return parameterNames.reduce((accumulator, parameterName) => {
      accumulator[parameterName] = searchParams.get(parameterName);
      return accumulator;
    }, {});
  }, [search, parameterNames.join(',')]); // join for sake of reducing array of strings to simple, comparable string
};

const useCaretPosition = (node = useRef(), shouldUpdate) => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  const updateCaret = useCallback(() => {
    // Get the updated caret postions from the ref passed in
    if (node && node.current) {
      const { selectionStart, selectionEnd } = node.current;

      setStart(selectionStart);
      setEnd(selectionEnd);
    }
  }, []);

  useEffect(() => {
    // Set the caret position by setting the selection range with the
    // most current start and end values
    if (node && node.current && shouldUpdate) {
      node.current.setSelectionRange(start, end);
    }
  });

  return { start, end, ref: node, updateCaret };
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const useCopyToClipboard = () => {
  const [copiedText, setCopiedText] = useState(null);

  const copy = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
};

const useUpdateEffect = (effectCallback, deps = []) => {
  const isFirstMount = useRef(false);

  useEffect(() => {
    return () => {
      isFirstMount.current = false;
    };
  }, []);
  useEffect(() => {
    // Do not execute effectcallback for the first time
    if (!isFirstMount.current) {
      isFirstMount.current = true;
    } else {
      return effectCallback();
    }
  }, deps);
};

// Hook for loading external scripts
const useScript = (src) => {
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(() => {
    if (!src) {
      setStatus('idle');
      return;
    }

    let script = document.querySelector(`script[src="${src}"]`);

    if (!script) {
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');
      document.body.appendChild(script);

      const setDataStatus = (event) => {
        script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
      };
      script.addEventListener('load', setDataStatus);
      script.addEventListener('error', setDataStatus);
    } else {
      setStatus(script.getAttribute('data-status'));
    }

    const setStateStatus = (event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };

    script.addEventListener('load', setStateStatus);
    script.addEventListener('error', setStateStatus);

    return () => {
      if (script) {
        script.removeEventListener('load', setStateStatus);
        script.removeEventListener('error', setStateStatus);
      }
    };
  }, [src]);

  return status;
};

const useIsFirstRender = () => {
  const isFirst = useRef(true)

  if (isFirst.current) {
    isFirst.current = false

    return true
  }

  return isFirst.current
}

export {
  useStateFromProp,
  usePrevious,
  useDelayedRender,
  useCountdown,
  useThrottledEffect,
  useDebouncedEffect,
  useSearchParams,
  useCaretPosition,
  useWindowDimensions,
  useCopyToClipboard,
  useUpdateEffect,
  useScript,
  useIsFirstRender,
};
