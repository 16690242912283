import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addNotification: ['notification', 'isTranslated'],
  createNotification: ['notification'],
  removeNotification: ['id'],
});

export const NotificationsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  notifications: [],
};

/* ------------- Reducers ------------- */

export const _createNotification = (state, { notification }) => {
  return {
    ...state,
    notifications: [...state.notifications, notification],
  };
};

export const _removeNotification = (state, { id }) => {
  return {
    ...state,
    notifications: state.notifications.filter((not) => not.id !== id),
  };
};

/* ------------- Selectors ------------- */

export const getNotifications = (state) => state.notifications.notifications;

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_NOTIFICATION]: _createNotification,
  [Types.REMOVE_NOTIFICATION]: _removeNotification,
});
