import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import picture from '~public/images/domestic-help.jpg';
import pictureWebp from '~public/images/domestic-help.jpg?format=webp';

import './style.scss';

const AboutPlatform = memo(({ alt, headline, content }) => {
  const intl = useIntl();

  return (
    <div className="c-about">
      <div className="c-about__image">
        <picture>
          <source srcSet={pictureWebp.srcSet} type="image/webp" />
          <img
            src={picture.src}
            srcSet={picture.srcSet}
            width="628"
            height="417"
            loading="lazy"
            alt={
              alt ||
              intl.formatMessage({
                id: `components.about-platform.image-alt`,
                defaultMessage: 'Domestic help',
              })
            }
          />
        </picture>
      </div>
      <div className="c-about__content">
        <h2 className="l-headline--secondary">
          {headline ||
            intl.formatMessage({
              id: `components.about-platform.headline`,
              defaultMessage: 'About this platform',
            })}
        </h2>
        <div className="c-about__text">
          {content ||
            intl.formatMessage({
              id: 'components.about-platform.content',
              defaultMessage: 'Lorem ipsum',
            })}
        </div>
      </div>
    </div>
  );
});

export default AboutPlatform;
