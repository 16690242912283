import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setWish: ['wish'],
  setOperations: ['operations'],
  setAccountType: ['accountType'],
  setEmail: ['email'],
  setReferrer: ['referrer'],
});

export const IntakeTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  wish: null, // client
  operations: null, // worker
  accountType: null,
  email: null,
  referrer: null,
};

/* ------------- Reducers ------------- */

export const _setWish = (state, { wish }) => {
  return { ...state, wish };
};

export const _setAccountType = (state, { accountType }) => {
  return { ...state, accountType };
};

export const _setEmail = (state, { email }) => {
  return { ...state, email };
};

export const _setReferrer = (state, { referrer }) => {
  return { ...state, referrer };
};

export const _setOperations = (state, { operations }) => {
  return { ...state, operations };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_WISH]: _setWish,
  [Types.SET_OPERATIONS]: _setOperations,
  [Types.SET_ACCOUNT_TYPE]: _setAccountType,
  [Types.SET_EMAIL]: _setEmail,
  [Types.SET_REFERRER]: _setReferrer,
});
