import React from 'react';
import cx from 'classnames';
import Loader from '~components/loader';

import './styles/index.scss';

const Button = ({ disabled = false, name, type, children, isLoading, className = 'c-btn', tabIndex, onClick = () => {} }) => {
  return (
    <button
      disabled={disabled}
      name={name}
      type={type}
      className={cx({
        [className]: !!className,
        'c-btn--is-loading': isLoading,
      })}
      onClick={onClick}
      data-cy="c-btn"
      tabIndex={tabIndex}
    >
      {isLoading && <Loader className="c-loader--absolute" />}
      <span
        className={cx({
          'l-visibility--hidden': isLoading,
        })}
      >
        {children}
      </span>
    </button>
  );
};

export default Button;
