import React, { useContext } from 'react';
import { AuthContext } from '~contexts/auth';

import { FiUser } from 'react-icons/fi';
import { useIntl } from 'react-intl';

import Button from '~components/button';

const SignIn = () => {
  const intl = useIntl();

  const { signIn } = useContext(AuthContext);

  return (
    <div className="c-header__sign-in">
      <Button className="c-btn--text" onClick={signIn}>
        <span className="c-header__sign-in-text">{intl.formatMessage({ id: 'components.header.sign-in', defaultMessage: 'Sign In' })}</span>
        <FiUser />
      </Button>
    </div>
  );
};

export default SignIn;
