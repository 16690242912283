import React, { memo } from 'react';
import { MdVerifiedUser } from 'react-icons/md';
import { useIntl } from 'react-intl';

import Tooltip from '~components/tooltip';
import './style.scss';

const VerificationBadge = memo(({ isVerified }) => {
  const intl = useIntl();

  if (!isVerified) {
    return null;
  }

  return (
    <Tooltip title={<MdVerifiedUser />} className="c-verification">
      {intl.formatMessage({ id: 'components.verification.verification-tooltip', defaultMessage: 'Verified' })}
    </Tooltip>
  );
});

export default VerificationBadge;
