import React from 'react';
import cx from 'classnames';

import './style.scss';

const Carousel = ({ children, className }) => {
  if (!children || !children.length) return null;

  return <div className={cx('c-carousel', { [className]: !!className })}>{children}</div>;
};

export default Carousel;
