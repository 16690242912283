import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
// import variables from 'scss/variables.scss';

const SkeletonPrimary = ({ children, containerclassName, className }) => {
  return (
    <SkeletonTheme className={className}>
      <span className={containerclassName}>{children}</span>
    </SkeletonTheme>
    // <SkeletonTheme color={variables.bColorLightGray} highlightColor={variables.bColorWhite} className={className}>
    //   <span className={containerclassName}>{children}</span>
    // </SkeletonTheme>
  );
};

export default SkeletonPrimary;
