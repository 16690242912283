import { searchCriteria } from '~containers/workers/list/searchCriteria';
import * as REST from '~services/rest';

export const getWorkersCount = (address, pricingDetails, wish) => {

    const criteria = searchCriteria({
      location: address,
      currency: pricingDetails?.currency,
      filters: {
        price: pricingDetails?.maxPrice,
        languages: wish?.languages || [],
        days: wish?.timeframes || [],
        attributes: wish?.attributes || [],
      }
    });

    return REST.get({
      name: 'workers',
      params: {
        count: true,
        ...criteria,
      },
    }).then((response) => {
      if (typeof response === 'undefined' || !response.data || !response.data[0]) {
        return null;
      }

      return response.data[0].count || 0;
    });
  }