import { LANGUAGES } from './languages';
import { COUNTRY_CODES } from './country-codes';

export { LANGUAGES, COUNTRY_CODES };

export const DEFAULT_COUNTRY_CODE = 'NL';
export const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const MAX_NOTE_LENGTH = 2000;
export const MIN_NOTE_LENGTH = 10;
export const ASYNC_VALIDATION_TIMEOUT_IN_MS = 1000;

export const TRAVEL_DISTANCE = {
  min: 1,
  max: 25,
};

export const DEFAULT_PRICE = {
  currency: 'USD',
  minPrice: 0,
  maxPrice: 4000,
};

export const STATIC_PAYMENT_PRICE = {
  amount: 1000,
  currency: 'EUR',
}
