import React, { memo } from 'react';
import { useIntl } from 'react-intl';

import './style.scss';

const HowItWorks = memo(() => {
  const intl = useIntl();

  return (
    <div className="how-it-works" itemScope itemType="https://schema.org/HowTo">
      <div className="how-t-works__subtitle">
        {intl.formatMessage({
          id: 'containers.how-it-works.subtitle',
          defaultMessage: 'How it works',
        })}
      </div>
      <h2 className="l-headline--secondary" itemProp="name">
        {intl.formatMessage({
          id: 'containers.how-it-works.headline',
          defaultMessage: 'How does it work?',
        })}
      </h2>
      <div className="how-it-works__wrapper">
        <div className="how-it-works__item" itemProp="step" itemScope itemType="https://schema.org/HowToStep">
          <img
            className="hiw-item__image"
            src="/images/jp-hiw-cleaner.svg"
            alt={intl.formatMessage({
              id: 'containers.how-it-works.section-one.headline',
              defaultMessage: '1. Find your match',
            })}
            itemProp="image"
            width={74}
            height={90}
            loading="lazy"
          />
          <div
            className="hiw-item__texts"
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/HowToDirection"
          >
            <div className="hiw-item__title" itemProp="name">
              {intl.formatMessage({
                id: 'containers.how-it-works.section-one.headline',
                defaultMessage: '1. Find your match',
              })}
            </div>
            <div className="hiw-item__content" itemProp="text">
              {intl.formatMessage({
                id: 'containers.how-it-works.section-one.content',
                defaultMessage: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ex mi.',
              })}
            </div>
          </div>
        </div>
        <div className="how-it-works__item" itemProp="step" itemScope itemType="https://schema.org/HowToStep">
          <img
            className="hiw-item__image"
            src="/images/jp-hiw-relationship.svg"
            alt={intl.formatMessage({
              id: 'containers.how-it-works.section-two.headline',
              defaultMessage: '2. Sign up',
            })}
            itemProp="image"
            width={109}
            height={90}
            loading="lazy"
          />
          <div
            className="hiw-item__texts"
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/HowToDirection"
          >
            <div className="hiw-item__title" itemProp="name">
              {intl.formatMessage({
                id: 'containers.how-it-works.section-two.headline',
                defaultMessage: '2. Sign up',
              })}
            </div>
            <div className="hiw-item__content" itemProp="text">
              {intl.formatMessage({
                id: 'containers.how-it-works.section-two.content',
                defaultMessage: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ex mi.',
              })}
            </div>
          </div>
        </div>
        <div className="how-it-works__item" itemProp="step" itemScope itemType="https://schema.org/HowToStep">
          <img
            className="hiw-item__image"
            src="/images/jp-hiw-credible.svg"
            alt={intl.formatMessage({
              id: 'containers.how-it-works.section-three.headline',
              defaultMessage: '3. Get in touch',
            })}
            itemProp="image"
            width={90}
            height={90}
            loading="lazy"
          />
          <div
            className="hiw-item__texts"
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/HowToDirection"
          >
            <div className="hiw-item__title" itemProp="name">
              {intl.formatMessage({
                id: 'containers.how-it-works.section-three.headline',
                defaultMessage: '3. Get in touch',
              })}
            </div>
            <div className="hiw-item__content" itemProp="text">
              {intl.formatMessage({
                id: 'containers.how-it-works.section-three.content',
                defaultMessage: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ex mi.',
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default HowItWorks;
