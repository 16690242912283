import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import 'regenerator-runtime/runtime';
import { persistStore, getStoredState, persistReducer } from 'redux-persist';
import { CookieStorage, NodeCookiesWrapper } from 'redux-persist-cookie-storage';
import Cookies from 'cookies';
import { default as CookiesJs } from 'cookies-js';

import reducers from '../redux';
import { rootSaga } from '../sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const composeEnhancers =
  process &&
  process.env &&
  process.env.NODE_ENV === 'development' &&
  typeof window !== 'undefined' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const getServerStore = async (req, res, { domainConfig }) => {
  const cookieJar = new NodeCookiesWrapper(new Cookies(req, res));

  const persistConfig = {
    key: 'root',
    storage: new CookieStorage(cookieJar /* , options */),
    whitelist: ['intake'], // Things u want to persist
    blacklist: ['domainConfig', 'subscription', 'payment', '_persist'], // Things u dont
    stateReconciler(inboundState, originalState) {
      // Ignore state from cookies, only use preloadedState from window object
      return originalState;
    },
  };

  let preloadedState;
  try {
    preloadedState = (await getStoredState(persistConfig)) || {};
  } catch (e) {
    // getStoredState implementation fails when index storage item is not set.
    preloadedState = {};
  }

  preloadedState.domainConfig = domainConfig;

  const store = createStore(reducers, preloadedState, composeEnhancers(applyMiddleware(...middlewares)));

  // Wait until persistor has completed deserialization
  store.__PERSISTOR = await persistStore(store);

  // Force cookies to be set
  await store.__PERSISTOR.flush();

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../redux', () => {
      const nextRootReducer = require('../redux').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

const getClientStore = async () => {
  const persistConfig = {
    key: 'root',
    storage: new CookieStorage(CookiesJs /* , options */),
    whitelist: ['intake'], // Things u want to persist
    blacklist: ['domainConfig', 'subscription', 'payment', '_persist'], // Things u dont
    stateReconciler(inboundState, originalState) {
      // Ignore state from cookies, only use preloadedState from window object
      return originalState;
    },
  };

  const store = createStore(
    persistReducer(persistConfig, reducers),
    window.__PRELOADED_STATE__,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  store.__PERSISTOR = await persistStore(store);

  sagaMiddleware.run(rootSaga);

  return store;
};

export const configureStore = async (req, res, params) => {
  if (typeof window !== 'undefined') {
    return getClientStore();
  }
  return getServerStore(req, res, params);
};

export default configureStore;
