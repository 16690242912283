import React from 'react';
import { FaSquareFacebook } from 'react-icons/fa6';
import { AiFillInstagram } from 'react-icons/ai';
import { FaSquareTwitter } from 'react-icons/fa6';
import { FaLinkedin } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import './style.scss';

const Socials = () => {
  const domainConfig = useSelector((state) => state.domainConfig);

  return (
    <div className="c-footer-socials l-container">
      <div className="c-footer-socials__content">
        <a
          href={domainConfig.facebook}
          title="Juan Pescador Facebook"
          rel="noopener noreferrer nofollow"
          aria-label="Juan Pescador Facebook"
          target="_blank"
        >
          <FaSquareFacebook />
        </a>
        <a
          href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2Fjuanpescadorservices%3Ffbclid%3DIwAR074aNIwQw0OLczF06tdVSEr0scCEaYgtx2-CnmShdDTvAp8i0AGI7n8jE&h=AT3DzbR-Gy7GoW71vT8xSqylq3GsCA5QoNITznUxbKWSbhCvBnKZp0SVHoMa5D4S9Kz70XzdSqf-EMwhQyDLROzz4Sk7f6fvWl6f2JSTs3x5VPCR57azwaRB8rGUuUjXUQLtXxnhFazsGON0FrCBgIHHD1I"
          title="Juan Pescador Instagram"
          rel="noopener noreferrer nofollow"
          aria-label="Juan Pescador Instagram"
          target="_blank"
        >
          <AiFillInstagram />
        </a>
        <a
          href="https://twitter.com/juanpescadorcom"
          title="Juan Pescador Twitter"
          rel="noopener noreferrer nofollow"
          aria-label="Juan Pescador Twitter"
          target="_blank"
        >
          <FaSquareTwitter />
        </a>
        <a
          href="https://www.linkedin.com/company/juan-pescador/"
          title="Juan Pescador LinkedIn"
          rel="noopener noreferrer nofollow"
          aria-label="Juan Pescador LinkedIn"
          target="_blank"
        >
          <FaLinkedin />
        </a>
      </div>
    </div>
  );
};

export default Socials;
