export const INTERNAL_PATHS = [
  '/favorites',
  '/chats',
  '/edit-offer',
  '/edit-wish',
  '/account',
  '/settings',
  '/password',
  '/discussion/:recipientId',
  '/review/:receiverId',
  '/payment/process',
  // '/workers',
  // '/customers',
];

export const isInternalRoute = (route) => {
  return INTERNAL_PATHS.some((r) => route.substring(0, r.length) === r);
};
