import { useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';

import { SeoContext } from '~components/seo/context';

export const useSeo = (title = null) => {
  const { onSeoChange } = useContext(SeoContext);
  const intl = useIntl();
  const defaultTitle = intl.formatMessage({
    id: 'default-seo.title',
  });

  useEffect(() => {
    onSeoChange({
      title: title || defaultTitle,
    });
  });
};
