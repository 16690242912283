/* eslint-disable no-return-assign */
import { put, takeEvery, all, select, delay } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import NotificationsActions, { NotificationsTypes, getNotifications } from '~redux/notifications';

const MAX_NOTIFICATIONS_COUNT = 3;
const NOTIFICATION_REMOVE_DELAY = 3000;

function* onAddNotification({ notification, isTranslated = true }) {
  const existingNotifications = yield select(getNotifications);
  if (existingNotifications.length >= MAX_NOTIFICATIONS_COUNT) {
    yield put(NotificationsActions.removeNotification(existingNotifications[0].id));
  }

  const id = uuidv4();
  yield put(
    NotificationsActions.createNotification({
      id,
      notification,
      isTranslated,
    })
  );

  yield delay(NOTIFICATION_REMOVE_DELAY);
  yield put(NotificationsActions.removeNotification(id));
}

export default function* userSaga() {
  yield all([takeEvery(NotificationsTypes.ADD_NOTIFICATION, onAddNotification)]);
}
