import React from 'react';
import { useIntl } from 'react-intl';

import { useLayout } from '~hooks/useLayout';
import { useSeo } from '~hooks/useSeo';

import HowItWorks from '~containers/how-it-works';
import Testimonials from '~containers/testimonials';

import Header from './header';
import List from './list';
import CTA from './cta';
import About from './about';
import FAQ from './faq';

const Homepage = () => {
  const intl = useIntl();

  useLayout({ displayCities: true, displaySignIn: true, displayTopCTABar: false, logoToBottom: true });
  useSeo(
    intl.formatMessage({
      id: 'containers.homepage.seo.title',
    })
  );

  return (
    <>
      <Header />
      <section className="c-page l-container c-page--homepage">
        <Testimonials />
        <HowItWorks />
        <List />
        <FAQ />
        <About />
        <CTA />
      </section>
    </>
  );
};

export default Homepage;
