export const searchCriteria = ({ location, filters = {}, currency, pagination, order }) => {
  const result = {};

  if (location && location.latitude && location.longitude) {
    result.latitude = location.latitude;
    result.longitude = location.longitude;
  }

  if (currency && typeof filters.price === 'number') {
    result.currency = currency;
    result.amount = filters.price;
  }

  if (filters.attributes) {
    result.attributes = filters.attributes;
  }
  if (filters.languages) {
    result.languages = filters.languages;
  }
  if (filters.days) {
    result.days = filters.days;
  }

  if (order) {
    if (order.direction) {
      result['order-direction'] = order.direction;
    }
    if (order.value) {
      result['order-type'] = order.value;
    }
  }

  if (pagination) {
    result['pagination-limit'] = pagination.limit;
    result['pagination-offset'] = pagination.offset;
  }

  return result;
};
