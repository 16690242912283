import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import FullLoader from '~components/loader/full-loader';

const defaultState = {
  isLoading: true,
  isAuthenticated: false,
};

export const AuthContext = React.createContext(defaultState);

const AuthProvider = ({ children }) => {
  const { domain } = useSelector((state) => state.domainConfig);
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();

  // if (isLoading) {
  //   return <FullLoader />;
  // }

  const signUp = () => {
    loginWithRedirect({
      authorizationParams: { screen_hint: 'signup' },
    });
  };

  const signIn = () => {
    loginWithRedirect();
  };

  const signOut = () => {
    logout({ logoutParams: { returnTo: `https://${domain}` } });
  };

  const getAccessToken = async () => {
    return getAccessTokenSilently();
  };

  const state = {
    isAuthenticated,
    isLoading,
    user,
    signUp,
    signIn,
    signOut,
    getAccessToken,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
