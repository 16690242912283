import { useState, useEffect, useRef, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDebouncedCallback } from 'use-debounce';
import * as REST from '~services/rest';
import { useAuth0 } from '@auth0/auth0-react';

export const useGet = (
  {
    name = '',
    cacheData = null,
    params,
    requiresAuthentication = false,
    shouldExecute = true,
    callback,
    mockFileName,
    shouldThrowError = false,
  },
  dependencies = []
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const fetch = async () => {
    if (requiresAuthentication && !isAuthenticated) {
      setIsLoading(false);
      return;
    }

    if (isFetching) {
      return;
    }

    setIsLoading(true);
    setIsFetching(true);

    let token = null;
    if (requiresAuthentication) {
      token = await getAccessTokenSilently();
    }

    const request = REST.get({ name, params, mockFileName, shouldThrowError, token }).then((response) => {
      if (!request.isCanceled) {
        setData(response);
      }
      setIsLoading(false);
      setIsFetching(false);
      return true;
    });
  };

  useEffect(async () => {
    if (!shouldExecute) {
      return;
    }

    if (cacheData) {
      setData(cacheData);
      setIsLoading(false);
      setIsFetching(false);
      return;
    }

    if (requiresAuthentication && !isAuthenticated) {
      setIsLoading(false);
      return;
    }

    if (isFetching) {
      return;
    }

    setIsLoading(true);
    setIsFetching(true);

    let token = null;
    if (requiresAuthentication) {
      token = await getAccessTokenSilently();
    }

    const request = REST.get({ name, params, mockFileName, shouldThrowError, token })
      .then((response) => {
        if (!request.isCanceled) {
          setData(response);
          setIsLoading(false);
          setIsFetching(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsFetching(false);
        setIsError(true);
        setError(err.response);
      });

    return () => {
      request.cancel();
      request.isCanceled = true;
    };
  }, [...dependencies, shouldExecute]);

  const callbackRef = useRef(null);
  useEffect(() => {
    if (!callback) {
      return;
    }

    if (callbackRef.current && !isLoading) {
      callback(data);
    }
    callbackRef.current = true;
  }, [isLoading]);

  if (cacheData) {
    return { data: cacheData, isLoading: false, isError: false, error: null, fetch };
  }

  if (requiresAuthentication && !isAuthenticated) {
    return { data: null, isLoading: false, isError: false, error: null, fetch };
  }

  return { data, isLoading, isError, error, fetch };
};

export const useDebounceQuery = (req, debounceTime, leading) => {
  const [pendingRequests, setPendingRequests] = useState([]);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = () => {
    setIsLoading(true);
    const requestId = uuidv4();
    const request = REST.get(req).then((res) => {
      if (typeof res === 'undefined') {
        setIsLoading(false);
        setResponse(null);
        return;
      }
      setResponse(res);
      setPendingRequests([]);
      setIsLoading(false);
    });

    pendingRequests.forEach((r) => r.request.cancel());
    setPendingRequests([{ request, id: requestId }]);
  };

  const fetchWithDebounce = useDebouncedCallback(() => fetchData(req), debounceTime, { leading });

  return [fetchWithDebounce, response, isLoading];
};
