import React from 'react';

export const mapRecursive = (children, maxNestLevel, callback) => {
  React.Children.map(children, (child) =>
    child.props && child.props.children && maxNestLevel > 0
      ? [
          callback(child),
          mapRecursive(child.props.children, maxNestLevel - 1, callback),
        ]
      : callback(child),
  );
};

export const getChildrenByType = (children, maxNestLevel = 1, type) => {
  const foundChildren = [];
  mapRecursive(children, maxNestLevel, (child) => {
    if (child && child.type === type) foundChildren.push(child);
  });
  return foundChildren;
};

export const getLabelFromChildren = (children) => {
  const label = '';
  React.Children.map(children, (child) => {
    if (typeof child === 'string') {
      return child;
    }
  });
  return label;
};
