// queryUtils.js
import queryString from 'query-string';

// Function to validate if a query string is properly formatted
export function isValidQueryString(urlparam) {
    try {
        queryString.parse(urlparam);
        return true;
    } catch {
        console.error("the malicious url param is ", urlparam);
        return false;
    }
}

// Function to sanitize the query string
export function sanitizeQueryString(urlparam) {
    // console.log("Sanitizing query string:", urlparam);

    // Step 1: Replace invalid percent-encoded sequences
    // This will catch cases where % is followed by non-hexadecimal characters
    let sanitized = urlparam
        .replace(/%[0-9A-Fa-f]{0,1}$/g, '%25')  // Handles incomplete percent-encoded sequences
        .replace(/%[^0-9A-Fa-f]/g, '%25');     // Replace any invalid percent-encoded sequences

    // Step 2: Handle trailing percent signs
    sanitized = sanitized.replace(/%$/, '%25');

    // Step 3: Encode special characters
    sanitized = sanitized.replace(/[\s<>\[\]{}|\\^`]/g, (char) => 
        `%${char.charCodeAt(0).toString(16).toUpperCase()}`
    );

    // add more sanitization logics here

    // console.log("Sanitized query string:", sanitized);
    return sanitized;
}


// Main function to check and sanitize query string
export function getValidatedQueryString(search) {
    // Check if the query string is valid
    if (isValidQueryString(search)) {
        return search;
    } else {
        // Sanitize the query string if invalid
        //return sanitizeQueryString(search);
        return "";
    }
}
