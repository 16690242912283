import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import feedbackPicture from '~public/images/feedback-company.png';

import './style.scss';

const Copyright = () => {
  const intl = useIntl();

  return (
    <div className="c-copyright l-container">
      <div className="c-copyright__content">
        <div className="c-copyright__text">
          &copy;
          {` Juan Pescador  ${new Date().getFullYear()}`}
        </div>
        <div className="c-copyright__nav c-horizontal-nav">
          <FormattedMessage id="routes.terms-and-conditions">
            {(msg) => (
              <Link to={`/${msg}`} className="c-horizontal-nav__item">
                {intl.formatMessage({ id: 'components.footer.copyright.terms-and-conditions' })}
              </Link>
            )}
          </FormattedMessage>
          <FormattedMessage id="routes.privacy-policy">
            {(msg) => (
              <Link to={`/${msg}`} className="c-horizontal-nav__item">
                {intl.formatMessage({ id: 'components.footer.copyright.privacy-policy' })}
              </Link>
            )}
          </FormattedMessage>
          <Link to="/sitemap" className="c-horizontal-nav__item">
            {intl.formatMessage({ id: 'breadcrumbs.sitemap' })}
          </Link>
        </div>
      </div>
      <div className="c-copyright__feedback-company c-feedback-company">
        <a
          className="c-feedback-company__link"
          href="https://www.feedbackcompany.com/nl-nl/reviews/juan-pescador/"
          rel="noopener noreferrer nofollow"
          aria-label="Feedback Company"
          target="_blank"
        >
          <img
            src={feedbackPicture}
            alt="Feedback Company" 
            className="c-feedback-company__logo"
            width="106"
            height="30"
            loading="lazy"
          />
        </a>
        <div className="c-feedback-company__score">
          {`${intl.formatMessage({
            id: 'containers.testimonials.feedback-company.score',
            defaultMessage: '6.9/10',
          })} ${intl.formatMessage({
            id: 'containers.testimonials.feedback-company.reviews-count',
            defaultMessage: '(11 reviews)',
          })}`}
        </div>
        <div id="c-feedback-company__script" />
      </div>
    </div>
  );
};

export default Copyright;
