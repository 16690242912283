import React, { useState, useRef } from 'react';
import cx from 'classnames';

import { useCaretPosition } from '~hooks/common';
import Loader from '~components/loader';
import useMaskedInput from './use-masked-input';
import withControlled from '../form/with-controlled';

import './style.scss';

const ENTER_KEY = 13;

export const Input = ({
  label,
  name,
  className,
  value,
  disabled = false,
  submitOnEnter = true,
  inputRef = useRef(),
  icon,
  placeholder,
  errors,
  isInvalid,
  mask,
  isValidating,
  onKeyUp = () => {},
  onChange = () => {},
  onBlur = () => {},
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);

  const inputMaskOnChange = mask
    ? useMaskedInput({
        mask,
        value,
        input: inputRef,
        onChange,
      })
    : null;

  // Track the caret position with the hook
  const { updateCaret } = useCaretPosition(inputRef, isActive);

  const handleKeyDown = (ev) => {
    if (!submitOnEnter && ev.keyCode === ENTER_KEY) {
      inputRef.current.blur();
      ev.preventDefault();
      onKeyUp(ev);
    }
  };

  const handleChange = (ev) => {
    if (mask) {
      setIsActive(true);
      inputMaskOnChange(ev);
      updateCaret();
      return;
    }

    onChange(ev);
  };

  const handleBlur = (ev) => {
    setIsActive(false);
    onBlur(ev);
  };

  return (
    <label
      className={cx('c-form__input', {
        'c-form__input--invalid': isInvalid && !disabled,
        [className]: !!className,
      })}
    >
      <div className="c-form__placeholder">{placeholder}</div>
      {label && <div className="c-form__label">{label}</div>}
      <input
        className={cx('c-input', {
          'c-input--with-icon': !!icon,
        })}
        name={name}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        onKeyUp={onKeyUp}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={inputRef}
        {...props}
      />
      {errors && !disabled && <div className="c-input__error">{errors.message}</div>}
      {icon && <div className="c-input__icon">{icon}</div>}
      {isValidating && <Loader className="c-input__validation-loader" />}
    </label>
  );
};

export const InputControlled = withControlled(Input);
