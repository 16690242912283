import React, { useState, useRef } from 'react';

import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import cx from 'classnames';

import './style.scss';

const Tooltip = ({ placement = 'top', title, children, className }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const referenceRef = useRef();
  const popperRef = useRef(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    strategy: 'absolute',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const setPopperRef = (v) => {
    popperRef.current = v;
    setPopperElement(v);
  };
  const setReferenceRef = (v) => {
    referenceRef.current = v;
    setReferenceElement(v);
  };

  return (
    <>
      <div
        className={className}
        ref={setReferenceRef}
        onMouseEnter={() => setIsPopupVisible(true)}
        onMouseLeave={() => setIsPopupVisible(false)}
        onClick={() => setIsPopupVisible(true)}
      >
        {title}
      </div>
      {isPopupVisible
        ?
        ReactDOM.createPortal(
            <div
              ref={setPopperRef}
              className={cx('c-tooltip__element', {
                isPopupVisible,
              })}
              style={styles.popper}
              {...attributes.popper}
            >
              <div className="c-tooltip__arrow" />
              {children}
            </div>,
            document.body
          )
          : null}
    </>
  );
};

export default Tooltip;
