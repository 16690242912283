import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PaymentActions from '~redux/payment';

import Request from './request';

import './style.scss';

const PaymentRequestModal = () => {
  const dispatch = useDispatch();

  const { isRequestModalVisible } = useSelector((state) => state.payment);

  const hide = useCallback(() => {
    dispatch(PaymentActions.toggleRequestModal(false));
    dispatch(PaymentActions.setModalData(null, null));
  });

  return isRequestModalVisible ? <Request onClose={hide} /> : null;
};

export default PaymentRequestModal;
