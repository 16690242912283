import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { useIntl } from 'react-intl';

import { INTERNAL_PATHS } from './internal-paths';
import PublicRoutes from './public-routes';

const InternalRoutes = loadable(() => import(/* webpackChunkName: "internal" */ './internal-routes'));
const Dynamic = loadable(() =>
  import(
    /* webpackChunkName: "dynamic",
      webpackPrefetch: true
       */
    '~containers/dynamic'
  )
);

const Routes = () => {
  const intl = useIntl();

  const PUBLIC_PATHS = [
    '/',
    '/search',
    '/workers',
    '/customers',
    '/logged-out',
    '/logout',
    '/after-login',
    '/register',
    '/authorize',
    '/finalize/email',
    '/finalize/external',
    '/verify/request',
    '/intake/client',
    '/intake/worker',
    '/worker/:personId',
    '/customer/:personId',
    '/sitemap',
    '/unsubscribe',
    '/unsubscribe-actions',
    `/${intl.formatMessage({ id: 'routes.faq' })}`,
    `/${intl.formatMessage({ id: 'routes.privacy-policy' })}`,
    `/${intl.formatMessage({ id: 'routes.terms-and-conditions' })}`,
    `/${intl.formatMessage({ id: 'routes.contact' })}`,
    `/${intl.formatMessage({ id: 'routes.cleaning-work' })}`,
  ];

  return (
    <Switch>
      <Route exact path="/appshell">
        <div style={{ opacity: 0 }}>Appshell</div>
      </Route>
      <Route exact path={INTERNAL_PATHS}>
        <InternalRoutes />
      </Route>
      <Route exact path={PUBLIC_PATHS}>
        <PublicRoutes />
      </Route>
      <Route>
        <Dynamic />
      </Route>
    </Switch>
  );
};

export default Routes;
