import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import deepmerge from 'deepmerge';

import ServerContext from '~src/serverContext';
import intlProvider from '~services/intlProvider';

import { getValidatedQueryString } from '~helpers/queryUtils';

function fetchTranslations(name = 'en_GB', domainConfig) {
  const promiseTranslations = axios.get(`${domainConfig.protocol}://${domainConfig.domain}/translations/${name}.json`).then(({ data }) => {
    return data;
  });

  const seoFile = name === 'eo_UY' ? 'Esperanto' : domainConfig.seo;
  const promiseSeo = axios.get(`${domainConfig.protocol}://${domainConfig.domain}/seo/${seoFile}.json`).then(({ data }) => {
    return data;
  });

  return Promise.all([promiseTranslations, promiseSeo]).then((values) => {
    return {
      translations: deepmerge(...values),
      langName: name.substring(0, 2),
    };
  });
}

export const useLocale = () => {
  const language = useSelector((state) => state.domainConfig.translations);
  const urlLocation = useLocation();
  const search = queryString.parse(getValidatedQueryString(urlLocation.search)) || null;

  if (search.translate && (search.translate === 'base' || search.translate === 'seo')) {
    return ['eo_UY'];
  }

  if (typeof search.locale !== 'undefined') {
    return [search.locale];
  }
  return [language];
};

export const isSeoCrowdin = (urlLocation) => {
  const search = queryString.parse(getValidatedQueryString(urlLocation.search)) || null;

  if (search.translate && search.translate === 'seo') {
    return true;
  }

  return false;
};

export const useTranslations = (defaultTranslations) => {
  if (defaultTranslations && Object.keys(defaultTranslations).length) {
    return [defaultTranslations, true];
  }

  const [translations, setTranslations] = useState(defaultTranslations);
  const [loaded, setLoaded] = useState(false);
  const [locale] = useLocale();
  const domainConfig = useSelector((state) => state.domainConfig);

  useEffect(() => {
    setLoaded(false);

    fetchTranslations(locale, domainConfig).then((response) => {
      setTranslations(response.translations);

      setLoaded(true);
    });
  }, [locale]);

  return [translations, loaded];
};

export const useRawIntl = (intlLocale, urlLocation) => {
  const cache = useContext(ServerContext);
  const serverTranslations = cache ? cache.get('serverTranslations') : null;
  const [isIntlInitialized, setIsIntlInitialized] = useState(!!serverTranslations);
  const [isInitializing, setIsInitializing] = useState(false);
  const [IProvider, setIProvider] = useState(null);

  const [translations] = useTranslations(serverTranslations || []);

  // ssr
  if (typeof window === 'undefined' && serverTranslations) {
    intlProvider.initialize({
      locale: intlLocale,
      messages: serverTranslations,
      isCrowdin: isSeoCrowdin(urlLocation),
    });
    return [intlProvider.getInstance(), true];
  }

  // frontend with ssr
  if (serverTranslations && !intlProvider.getInstance()) {
    intlProvider.initialize({
      locale: intlLocale,
      messages: translations,
      isCrowdin: isSeoCrowdin(urlLocation),
    });
    return [intlProvider.getInstance(), true];
  }

  // frontend without ssr
  if (!serverTranslations) {
    useEffect(() => {
      if (Object.keys(translations).length > 0 && !intlProvider.getInstance() && !isInitializing) {
        setIsInitializing(true);
        intlProvider.initialize({
          locale: intlLocale,
          messages: translations,
          isCrowdin: isSeoCrowdin(urlLocation),
        });
        setIsInitializing(false);
        setIProvider(intlProvider.getInstance());
        setIsIntlInitialized(true);
      }
    }, [translations]);

    return [IProvider, isIntlInitialized];
  }
  return [intlProvider, isIntlInitialized];
};
