import React, { useState, useEffect, useRef } from 'react';
import { useDebounce } from 'use-debounce';
import { useIntl } from 'react-intl';
import { FiSearch } from 'react-icons/fi';
import cx from 'classnames';

import * as REST from '~services/rest';

import Autocomplete from '~components/forms/autocomplete';
import withControlled from '../form/with-controlled';

import './style.scss';

const DEBOUNCE_TIME = 500;
const MIN_CHARACTERS = 3;

export const LocationSearch = ({
  placeholder,
  className,
  fieldName,
  location = null,
  isDataLoading,
  onChange = () => {},
  onInputClick = () => {},
  onSubmit = () => {},
  onValueChange = () => {},
  onSuggestionsChange = () => {},
  errors,
  disabled,
  inputRef,
  ...props
}) => {
  const intl = useIntl();

  const [value, setValue] = useState(location ? location.address : '');

  const [touch, setTouch] = useState(false);
  const [debouncedValue] = useDebounce(value, DEBOUNCE_TIME, { leading: true });
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(async () => {

    if (!debouncedValue || debouncedValue.length < MIN_CHARACTERS) {
      setSuggestions([]);
      return;
    }
    setIsLoading(true);

    try {
      const request = await REST.get({
        name: `locations`,
        mockFileName: 'locations/get-by-partial',
        shouldThrowError: true,
        params: {
          'partial-address': debouncedValue,
        },
      });

      if (typeof request === 'undefined' || !request.data) {
        return;
      }

      setSuggestions(request.data);

    } catch(err) {
      return;
    } finally {
      setIsLoading(false);
    }

    return () => {
      request.cancel();
    };
  }, [debouncedValue]);

  useEffect(() => {
    if (suggestions.length) {
      onSuggestionsChange(suggestions);
      setTouch(true);
    }
  }, [suggestions]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (!value || (value && !location) || value !== location.address) {
      onChange(null);
    }
    if (value && value.length >= MIN_CHARACTERS) {
      setIsLoading(true);
    }
  }, [value]);

  useEffect(() => {
    if (location) {
      setValue(location.address);
    }
  }, [location]);

  return (
    <>
      <Autocomplete.Container
        className={cx('c-location-search', className)}
        placeholder={placeholder}
        onChange={(val) => {
          setValue(val);
          onChange(val);
          onValueChange(val);
        }}
        onSubmit={onSubmit}
        onInputClick={() => {
          onInputClick();
          setTouch(true);
        }}
        initialValue={value}
        isLoading={isDataLoading || isLoading}
        disabled={isDataLoading}
        inputRef={inputRef}
        name={fieldName}
        showErrors={false}
        icon={<FiSearch />}
        {...props}
      >
        <Autocomplete.Toggle>
          {/* {!value && !isLoading && !isDataLoading && <div className="c-location__powered_by" />} */}
        </Autocomplete.Toggle>
        <Autocomplete.Content>
          {suggestions && suggestions.length > 0 && (
            <div className="c-location__options">
              {suggestions.map((s) => (
                  <Autocomplete.Suggestion
                    key={s.place_id}
                    label={s.address}
                    value={s}
                    className="c-autocomplete__option"
                    onClickHandler={(newSelection) => {
                      setValue(newSelection.address);
                      onChange(newSelection);
                    }}
                  >
                    {s.address}
                  </Autocomplete.Suggestion>
              ))}
            </div>
          )}
        </Autocomplete.Content>
        <Autocomplete.Empty>
          {touch && !isLoading && !isDataLoading && (
            <div className="c-location__options">
              <div className="c-autocomplete__message">
                {intl.formatMessage({
                  id: 'components.forms.location.no-matches',
                  defaultMessage: 'No matches',
                })}
              </div>
            </div>
          )}
        </Autocomplete.Empty>
      </Autocomplete.Container>
      {errors && !disabled && <div className="c-input__error c-input__error--relative">{errors.message}</div>}
    </>
  );
};

export const LocationSearchControlledWrapper = ({ value, onChange = () => {}, ...props }) => {
  const handleAddressSuggestions = (locations) => {
    onChange(locations[0]);
  };

  const handleAddressChange = (loc) => {
    onChange(loc);
  };

  const handleInputChange = () => {
    onChange(null);
  };

  return (
    <LocationSearch
      onSuggestionsChange={(suggestions) => handleAddressSuggestions(suggestions)}
      onSubmit={(loc) => handleAddressChange(loc)}
      // onChange={(loc) => handleInputChange(loc)}
      onValueChange={(loc) => handleInputChange(loc)}
      {...props}
    />
  );
};

export const LocationSearchControlled = withControlled(LocationSearchControlledWrapper);
