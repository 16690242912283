import React, { useEffect } from 'react';

const Crowdin = ({ baseEnabled = false, seoEnabled = false }) => {
  const projectName = seoEnabled ? 'jp2022-seo' : 'jp2022-frontend';

  useEffect(() => {
    if (baseEnabled || seoEnabled) {
      const script1 = document.createElement('script');
      script1.setAttribute('type', 'text/javascript');
      script1.innerHTML = `{var _jipt = []; _jipt.push(['project', '${projectName}']);}`;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script1.setAttribute('type', 'text/javascript');
      script2.setAttribute('src', '//cdn.crowdin.com/jipt/jipt.js');
      document.head.appendChild(script2);
    }
  }, [baseEnabled, seoEnabled]);

  return <></>;
};

export default Crowdin;
