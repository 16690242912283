import { createReducer } from 'reduxsauce';

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  translations: 'en_GB',
  scope: 'country',
  languages: ['de', 'en', 'fr', 'es'],
  analytics: 'UA-28933646-14',
  inspectlet: '760742048',
  facebook: 'JuanpescadorNL',
  minPrice: 1000,
  maxPrice: 3000,
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {});
