import React from 'react'
import Stars from '~components/stars';

import './style.scss';

const Testimonial = ({ name, date, score, stars, content}) => {
  return (
    <div className="c-testimonial">
        <div className="c-testimonial__name">{name}</div>
        <div className="c-testimonial__date">{date}</div>
        <div className="c-testimonial__score">
            <Stars value={stars} />
            <div className="c-testimonial__score-value">{score}</div>
        </div>
        <div className="c-testimonial__content">{content}</div>
    </div>
  )
}

export default Testimonial