import React, { useContext } from 'react';
import { ValueContext } from './context';

const Suggestion = ({ label, value, className, children, onClickHandler, ...props }) => {
  const { suggestion, onSuggestionChange } = useContext(ValueContext);

  const handleClick = (ev) => {
    ev.preventDefault();
    onSuggestionChange(label, value);
    if (onClickHandler) onClickHandler(value);
  };

  return (
    <div className={className}onClick={handleClick}>
      {children}
    </div>
  );
};

export default Suggestion;
