import { useContext} from 'react';
import { getListingRedirect } from '~helpers/common';
import { UserContext } from '~contexts/user';

export const useListingRedirect = () => {

  const { user } = useContext(UserContext);

  const locationId =
  user.accountType === 'worker'
    ? user?.operations?.address?.place_id || null
    : user?.wish?.address?.place_id || null;

  return user?.accountType ? getListingRedirect(user.accountType, locationId) : '/';

}