import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FiInfo, FiX } from 'react-icons/fi';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import NotificationsActions from '~redux/notifications';

import './style.scss';

const Item = ({ id, children }) => {
  const dispatch = useDispatch();
  return (
    <div className="c-notification">
      <div className="c-notification__icon">
        <FiInfo />
      </div>
      <div className="c-notification__content">{children}</div>
      <div className="c-notification__close">
        <FiX onClick={() => dispatch(NotificationsActions.removeNotification(id))} />
      </div>
    </div>
  );
};

const Notifications = () => {
  const notifications = useSelector((state) => state.notifications.notifications);
  const intl = useIntl();

  if (typeof window === 'undefined') {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      {notifications.map((n) => {
        return (
          <Item key={n.id} id={n.id}>
            {n.isTranslated
              ? n.notification
              : intl.formatMessage({
                  id: n.notification,
                })}
          </Item>
        );
      })}
    </>,
    // document.body
    document.querySelector('#notifications-container')
  );
};

export default Notifications;
