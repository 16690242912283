import * as yup from 'yup';
import moment from 'moment';

yup.addMethod(yup.mixed, 'isValidDate', function () {
  return this.test({
    name: 'is-valid-date',
    message: 'components.form.validation.date.is-valid-date',
    test: (value) => {
      if (!value) {
        return true;
      }
      if (value.indexOf('_') >= 0) {
        return false;
      }

      return moment(value, 'DD-MM-YYYY').isValid();
    },
  });
});

yup.addMethod(yup.mixed, 'isBeforeNow', function () {
  return this.test({
    name: 'is-before-now',
    message: 'components.form.validation.date.is-before-now',
    test: (value) => {
      if (!value) {
        return true;
      }
      return moment(value, 'DD-MM-YYYY').isBefore(moment());
    },
  });
});

yup.addMethod(yup.mixed, 'isAfter1900', function () {
  return this.test({
    name: 'is-after-1900',
    message: 'components.form.validation.date.is-after-1900',
    test: (value) => {
      if (!value) {
        return true;
      }
      return moment(value, 'DD-MM-YYYY').isAfter(moment('1900-01-01', 'YYYY-MM-DD'));
    },
  });
});
