import React from 'react';
import { FaStarHalfAlt, FaStar, FaRegStar } from 'react-icons/fa';

import cx from 'classnames';

import './style.scss';

const Stars = ({ value, className, disabled }) => {
  return (
    <div
      className={cx('c-stars', {
        [className]: !!className,
        'c-stars--disabled': disabled,
      })}
    >
      {Array(5)
        .fill()
        .map((item, index) => {
          if (disabled && !value) {
            return <FaStar key={index} className="c-stars__item c-star--disabled" />;
          }
          return index + 1 <= value ? (
            <FaStar key={index} className="c-stars__item c-star--full" />
          ) : value - index >= 0.5 ? (
            <FaStarHalfAlt key={index} className="c-stars__item c-star--half" />
          ) : (
            <FaRegStar key={index} className="c-stars__item c-star--empty" />
          );
        })}
    </div>
  );
};

export default Stars;
