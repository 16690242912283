import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { AuthContext } from '~contexts/auth';
import { UserContext } from '~contexts/user';
import * as REST from '~services/rest';
import { useWebsocketEvent } from '~contexts/socket';

import Dropdown from '~components/dropdown/';
import { Suspense } from '~components/suspense';

import Toggle from './toggle';
import Loader from './loader';
import Message from './message';

import './style.scss';

const Conversations = () => {
  const intl = useIntl();
  const urlLocation = useLocation();

  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(1);
  const [messages, setMessages] = useState(null);
  const [unseenMessages, setUnseenMessages] = useState([]);

  const { getAccessToken } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const { personId } = user;

  // @TODO useDebouncedCallback
  useEffect(async () => {
    if (!isLoading) {
      return;
    }

    try {
      const token = await getAccessToken();
      const result = await REST.get({
        name: `chats/`,
        mockFileName: 'chats/get-unseen',
        shouldThrowError: true,
        params: {
          'participant-id': personId,
          unseen: true,
        },
        token,
      });

      if (result && result.data && result.data.length > 0) {
        const nMessages = result.data.map((n) => {
          const author = n.author.find((a) => a.id !== personId);
          return {
            discussionId: n.id,
            lastMessage: {
              createdAt: n?.last_message[0]?.sent_timestamp,
              messageId: n?.last_message[0]?.id,
              body: intl.formatMessage({
                id: `components.header.conversations.message-content`,
                defaultMessage: 'New message',
              })
            },
            sender: {
              personId: author.id,
              firstName: author.first_name,
              profilePicture: author.profilePicture,
            },
          };
        });
        setMessages(nMessages);
        setUnseenMessages(nMessages.map((m) => m.discussionId));
      } else {
        setUnseenMessages([]);
      }
    } catch (err) {
      return;
    } finally {
      setIsLoading(false);
    }
  }, [isLoading]);

  const updateMessages = useCallback((ev) => {
    if (ev.participant_id !== personId && urlLocation.pathname !== `/discussion/${ev.participant_id}`) {
      setIsLoading(Date.now());
    }
  });

  const updateLastSeen = useCallback((ev) => {
    if (ev.participant_id === personId) {
      setIsLoading(Date.now());
    }
  });

  useWebsocketEvent('seen', updateLastSeen);
  useWebsocketEvent('sent', updateMessages);

  const messagesWithLastMessage = useMemo(
    () => (messages && messages.length > 0 ? messages.filter((m) => !isEmpty(m.lastMessage)) : []),
    [messages]
  );

  const handleOpenNotification = async (message) => {
    setVisible(false);

    try {
      const token = await getAccessToken();
      await REST.post({
        name: `chats/${message.discussionId}`,
        requiresAuthentication: true,
        mockFileName: 'chats/post-as-seen',
        params: {
          action: 'seen',
          who: personId,
          receiver: message.sender.personId,
        },
        token,
      });

      setUnseenMessages((prevUnseenMessages) => prevUnseenMessages.filter((m) => m !== message.discussionId));
    } catch (err) {
      console.log('Error while marking message as seen', err);
    }
  };

  if (!personId) return null;

  return (
    <div className="c-horizontal-nav__item c-header__navitem c-header__navitem--mobile c-context-menu__wrapper c-header-conversations">
      <Dropdown.Container
        placement="bottom-start"
        visible={visible}
        onVisibleChange={setVisible}
        optionsClassName="c-context-menu c-header__context-menu c-header-conversations__options"
      >
        <Dropdown.Toggle>
          <Toggle onToggle={() => setVisible(!visible)} count={unseenMessages.length} />
        </Dropdown.Toggle>
        <Dropdown.Content>
          <>
            <div className="c-context-menu__section l-headline--tertiary">
              {intl.formatMessage({ id: 'components.header.conversations.headline', defaultMessage: 'Last messages' })}
            </div>
            <Suspense isLoading={isLoading} fallback={<Loader />}>
              {messagesWithLastMessage && messagesWithLastMessage.length > 0 ? (
                messagesWithLastMessage.map((message) => (
                  <Message
                    key={message.lastMessage.messageId}
                    personId={message.sender.personId}
                    firstName={message.sender.firstName}
                    profilePicture={message.sender.profilePicture}
                    body={message.lastMessage.body}
                    onToggle={() => handleOpenNotification(message)}
                  />
                ))
              ) : (
                <div className="c-context-menu__section">
                  {intl.formatMessage({
                    id: 'components.header.conversations.no-messages',
                    defaultMessage: 'No messages',
                  })}
                </div>
              )}
            </Suspense>
          </>
        </Dropdown.Content>
      </Dropdown.Container>
    </div>
  );
};

export default Conversations;
