import React from 'react';
import cx from 'classnames';

import './style.scss';

const Wrapper = ({ children, className }) => {
  return <div className={cx('c-hero', { [className]: !!className })}>{children}</div>;
};

const Title = ({ children, className }) => {
  return (
    <div className={cx('c-hero__title', { [className]: !!className })}>
      <h1 className="l-headline--primary">{children}</h1>
    </div>
  );
};

const Buttons = ({ children }) => {
  return <div className="c-hero__buttons">{children}</div>;
};

const Intro = ({ children }) => {
  return <div className="c-hero__intro">{children}</div>;
};

export default {
  Wrapper,
  Title,
  Buttons,
  Intro,
};
