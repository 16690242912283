import React from 'react';
import { Link } from 'react-router-dom';
import { renderFirstName } from '~helpers/common';

import Avatar from '~components/avatar';

const Message = ({ personId, firstName, profilePicture, body, onToggle = () => {} }) => {
  const fName = renderFirstName(firstName);

  return (
    <div className="c-context-menu__section">
      <Link to={`/discussion/${personId}`} className="c-conversations__link" onClick={onToggle}>
        <div className="c-recent-message">
          <Avatar profilePicture={profilePicture} firstName={fName} className="c-recent-message__person" />
          <div className="c-recent-message__text">{`${fName}: ${body}`}</div>
        </div>
      </Link>
    </div>
  );
};

export default Message;
