import React from 'react';
import cx from 'classnames';
import useDraggable from '~hooks/useDragScroll';

import Review from './review';

import './style.scss';

const ReviewsListContent = ({ reviews, className, headline, isLoading }) => {
  const dragabble = useDraggable();

  return (
    <div
      className={cx('c-reviews-list', {
        [className]: !!className,
        'c-reviews-list--no-results': !isLoading && (!reviews || reviews.length === 0),
      })}
      ref={dragabble}
    >
      <div className="l-headline--tertiary">{headline}</div>
      <div className="c-reviews-list__results">
        {reviews.map((r, index) => {
          return <Review key={index} firstName={r.author_first_name} score={r.score} comment={r.text} />;
        })}
      </div>
    </div>
  );
};

export default ReviewsListContent;
