import React, { useContext } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { matchJPPath } from '~helpers/common';

import cx from 'classnames';

import { UserContext } from '~contexts/user';

import { workerMenuItems, clientMenuItems } from '../menu-items';

import './style.scss';

const ScopedMenu = ({ menuItems }) => {

  const intl = useIntl();
  const { pathname } = useLocation();

  return menuItems.map((item) => (
    <Link
      to={item.pathname}
      key={item.pathname}
      className={cx('c-horizontal-nav__item', {
        'c-horizontal-nav__item--active': matchJPPath(item.pathname, pathname),
      })}
    >
      {intl.formatMessage({ id: item.translation, defaultMessage: item.defaultMessage })}
    </Link>
  ));
};

const AuthorizedMenu = () => {
  const { user } = useContext(UserContext);
  const { accountType, operations, wish } = user;

  const menuItems =
    accountType === 'worker'
      ? workerMenuItems({ locationId: operations?.address?.place_id || null })
      : clientMenuItems({ locationId: wish?.address?.place_id || null });

  return menuItems && menuItems.length > 0 && accountType ? (
    <div className="c-authorized-menu__container">
      <div className="l-container">
        <div className={`c-horizontal-nav c-authorized-menu c-authorized-menu--${accountType}`}>
          <ScopedMenu menuItems={menuItems} />
        </div>
      </div>
    </div>
  ) : null;
};

export default AuthorizedMenu;
