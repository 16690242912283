import React, { useState, useEffect, useContext } from 'react';
import io from 'socket.io-client';

import { UserContext } from '~contexts/user';

export const SocketContext = React.createContext();

const Socket = ({ domain, path, addTrailingSlash, children }) => {
  const [socket, setSocket] = useState(null);
  const [status, setStatus] = useState('not-initialized');

  useEffect(() => {
    if (status === 'not-initialized') {
      setSocket(
        io(domain, {
          transports: ['polling'],
          path,
          reconnection: false,
          autoConnect: false,
          addTrailingSlash,
        })
      );
      setStatus('initialized');
    }

    return () => {
      if (status !== 'not-initialized') {
        socket.off('connect');
        socket.off('disconnect');
        socket.off('pong');
      }
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
        status,
      }}
    >
      <SocketRoom>{children}</SocketRoom>
    </SocketContext.Provider>
  );
};

const SocketRoom = ({ children }) => {
  const { socket } = useContext(SocketContext);
  const { user } = useContext(UserContext);

  const { personId } = user;

  useEffect(() => {
    if (socket && personId && !socket.connected) {
      socket.connect();
      socket.emit('JOINED', { person_id: personId });
      // socket.onAny((event, ...args) => {
      //   console.log(`got ${event} with args:`, args);
      // });
      return;
    }

    // If no websocketChannelName and we're connected - disconnect
    if (socket && socket.status === 'connected') {
      socket.emit('terminate');
    }
  }, [personId, socket]);

  useEffect(() => {
    return () => {
      if (socket && socket.status === 'connected') {
        socket.emit('terminate');
      }
    };
  }, []);

  return <>{children}</>;
};

export const useWebsocketEvent = (event, handler) => {
  const { socket } = useContext(SocketContext);
  const [eventRegistered, setEventRegistered] = useState(false);

  if (socket === undefined) {
    throw new Error('useEvent must be used within a SocketProvider');
  }

  useEffect(() => {
    if (!socket || eventRegistered) {
      return;
    }

    socket.on(event, handler);
    setEventRegistered(true);

    return () => {
      socket.off(event, handler);
      setEventRegistered(false);
    };
  }, []);
};

export default Socket;
