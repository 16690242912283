import React, { useEffect, useState, useContext } from 'react';
import { PaymentElement, LinkAuthenticationElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { AuthContext } from '~contexts/auth';

import Modal from '~components/modal';
import Button from '~components/button';

import './style.scss';

const CheckoutForm = ({ returnUrl }) => {
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();
  const { websiteTitle } = useSelector((state) => state.domainConfig);
  const { user } = useContext(AuthContext);

  const [email, setEmail] = useState(user?.email || '');
  const [message, setMessage] = useState(null);
  const [customMessage, setCustomMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('containers.payment.request.modal.stripe.message.success');
          break;
        case 'processing':
          setMessage('containers.payment.request.modal.stripe.message.processing');
          break;
        case 'requires_payment_method':
          setMessage('containers.payment.request.modal.stripe.message.requires-payment');
          break;
        default:
          setMessage('containers.payment.request.modal.stripe.message.error');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: returnUrl,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setCustomMessage(error.message);
    } else {
      setMessage('containers.payment.request.modal.stripe.message.error');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  };

  return (
    <>
      <Modal.Header>
        <div className="l-center">
          <img alt={websiteTitle.toLowerCase()} src="/images/logo.svg" />
          <h2 className="l-headline--secondary">
            {intl.formatMessage({
              id: 'containers.payment.request.modal.stripe.header',
              defaultMessage: 'Checkout',
            })}
          </h2>
        </div>
      </Modal.Header>
      <Modal.Content className="c-stripe__modal-content">
        <form className="c-stripe" id="payment-form" onSubmit={handleSubmit}>
          <LinkAuthenticationElement
            id="link-authentication-element"
            options={{ defaultValues: { email } }}
            onChange={(e) => setEmail(e?.target?.value || '')}
            className="l-mb1"
          />
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <div className="l-center">
            <Button disabled={isLoading || !stripe || !elements} isLoading={isLoading} id="submit">
              {intl.formatMessage({
                id: 'containers.payment.request.modal.stripe.pay-now',
                defaultMessage: 'Pay now',
              })}
            </Button>
          </div>
          {/* Show any error or success messages */}
          {message && (
            <div id="payment-message">
              {intl.formatMessage({
                id: message,
                defaultMessage: 'Error',
              })}
            </div>
          )}
          {/* Card processing specific messages */}
          {customMessage && <div id="payment-message">{message}</div>}
        </form>
      </Modal.Content>
    </>
  );
};

export default CheckoutForm;
