import React, { useContext } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useListingRedirect } from '~hooks/useListingRedirect';
import cx from 'classnames';

import { UserContext } from '~contexts/user';
import { LayoutContext } from '~contexts/layout';

import AccountMenu from './account-menu';
import Conversations from './conversations';
import MainMenu from './main-menu';
import SignIn from './sign-in';
import Finalize from './finalize';
import AddOffer from './add-offer';
import AuthorizedMenu from './authorized-menu';
import TopCTABar from './top-cta-bar';
import InstallPWAButton from './install-pwa-button';

import './style.scss';
import DesktopMenu from './desktop-menu';

const Header = () => {
  const websiteTitle = useSelector((state) => state.domainConfig.websiteTitle);
  const location = useLocation();

  const { user: authUser, isAuthenticated, isLoading: isLoadingAuth } = useAuth0();
  const { user, isLoading: isLoadingBackendUser, isLoaded } = useContext(UserContext);
  const { layout } = useContext(LayoutContext);

  const needsFinalize =
    isAuthenticated &&
    !isLoadingBackendUser &&
    isLoaded &&
    !user?.personId &&
    location.pathname.indexOf('/finalize/') < 0;

  const hasWishOrOperations = !!user?.wish?.address?.place_id || !!user.operations?.address?.place_id;
  const hasPublishedWish = !!user?.wish?.address?.place_id && user?.wish?.published;

  const finalizeUrl =
    needsFinalize && authUser?.attributes?.identities?.length > 0 ? '/finalize/external' : '/finalize/email';

  const listingRedirect = useListingRedirect();

  return (
    <>
      <header
        className={cx('c-header', {
          ['c-header--no-mobile']: layout.type === 'no-header-on-mobile',
          ['c-header--with-top-cta-bar']: !isAuthenticated && layout.displayTopCTABar,
          ['c-header--logo-to-bottom']: !isAuthenticated && layout.logoToBottom,
        })}
      >
        {/* commented as per reuirement */}
        {!isAuthenticated && layout.displayTopCTABar ? <TopCTABar /> : null}
        <div className="c-header__inner l-container">
          <div className="c-header__item c-header__item--left">
            {!isAuthenticated && <MainMenu />}
            {isAuthenticated && user?.personId ? <InstallPWAButton /> : null}
          </div>

          <div className="c-header__item c-header__item--center">
            <div className="c-header-website">
              <Link to={listingRedirect}>
                <img alt={websiteTitle.toLowerCase()} src="/images/logo.svg" width="64" height="64" />
              </Link>
            </div>
            {!isAuthenticated && <DesktopMenu />}
          </div>

          <div className="c-header__item c-header__item--right">
            <div className="c-horizontal-nav c-header__nav">
              {isAuthenticated && user?.personId && hasWishOrOperations ? <Conversations /> : null}
              {isAuthenticated && user?.personId && hasWishOrOperations ? <AccountMenu /> : null}
              {!hasPublishedWish && user?.accountType !== 'worker' ? <AddOffer /> : null}
              {!isAuthenticated && layout.displaySignIn && !isLoadingAuth ? <SignIn /> : null}
              {needsFinalize && <Finalize url={finalizeUrl} />}
            </div>
          </div>
        </div>
      </header>
      {isAuthenticated && user && hasWishOrOperations ? <AuthorizedMenu /> : null}
    </>
  );
};

export default Header;
