import { useEffect, useContext, useReducer } from 'react';
import ServerContext from '~src/serverContext';

import * as REST from '~services/rest';
import { DEFAULT_PRICE } from '~constants/index.js';

export const useLocationDetails = (locationId, dependencies = []) => {
  const cache = useContext(ServerContext);
  const cachedLocation = cache.get('serverLocation');

  const [locationInfo, updateLocationInfo] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'setDetails':
          return {
            ...state,
            pricingDetails: action.pricingDetails,
            locationDetails: action.locationDetails,
            isLoading: false,
          };
        case 'setLocationTouched':
          return {
            ...state,
            locationTouched: true,
          };
        case 'setLoading':
          return {
            ...state,
            isLoading: action.isLoading,
          };
        default:
          throw Error(`Unknown action: ${action.type}`);
      }
    },
    {
      locationDetails: cachedLocation?.locationId === locationId ? cachedLocation : null,
      pricingDetails: null,
      isLoading: cachedLocation?.locationId !== locationId,
      locationTouched: false,
    }
  );

  const requestLocationByID = async (locId) => {
    updateLocationInfo({ type: 'setLocationTouched' });
    if (!locId) {
      updateLocationInfo({ type: 'setLoading', isLoading: false });
      return;
    }

    try {
      updateLocationInfo({ type: 'setLoading', isLoading: true });
      const response = await REST.get({
        name: `locations`,
        mockFileName: 'locations/get-by-id',
        params: {
          'place-id': locId,
        },
      });

      const newDetails = {
        locationDetails: null,
        pricingDetails: DEFAULT_PRICE,
      };

      if (response && response.data && response.data[0] && response.data[0].location) {
        const locData = response.data[0].location;
        newDetails.locationDetails = {
          ...locData,
          locationId: locData.place_id,
          address: locData.address,
        };
      }

      if (response && response.data && response.data[0] && response.data[0].pricing) {
        const priceData = response.data[0].pricing;
        if (priceData.currency && priceData.min_price !== null && priceData.max_price !== null) {
          newDetails.pricingDetails = {
            currency: priceData.currency,
            minPrice: priceData.min_price,
            maxPrice: priceData.max_price,
          };
        }
      }

      updateLocationInfo({
        type: 'setDetails',
        locationDetails: newDetails.locationDetails,
        pricingDetails: newDetails.pricingDetails,
      });
    } catch (err) {
      console.log('Error while fetching location details: ', err);
    } finally {
      updateLocationInfo({ type: 'setLoading', isLoading: false });
    }
  };

  const fetchCustomerPaymentForLocation = async (customerId) => {
    try {
      const response = await REST.get({
        name: `locations`,
        // mockFileName: 'locations/get-by-id',
        params: {
          'customer_id': customerId,
        },
      });

      return response.data;
    } catch (err) {
      console.log('Error while fetching customer payment for location details: ', err);
    }
  };


  useEffect(async () => {
    if ((locationId && !locationInfo.locationDetails) || locationId !== locationInfo.locationDetails?.locationId) {
      await requestLocationByID(locationId);
    }
  }, dependencies);

  return {
    locationDetails: locationInfo.locationDetails,
    pricingDetails: locationInfo.pricingDetails,
    isLoading: locationInfo.isLoading,
    locationTouched: locationInfo.locationTouched,
    requestLocationByID,
    fetchCustomerPaymentForLocation,
  };
};
