import { createReducer, createActions } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setModalData: ['contactId', 'locationId'],
  toggleRequestModal: ['isVisible'],
  addPayedWorker: ['customerId', 'workerId'],
});

export const PaymentTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

export const INITIAL_STATE = {
  isRequestModalVisible: false,
  requestedContactId: null,
  locationId: null,
  payedWorkers: [],
};

/* ------------- Reducers ------------- */

export const _setModalData = (state, { contactId, locationId }) => {
  return {
    ...state,
    requestedContactId: contactId,
    locationId,
  };
};

export const _toggleRequestModal = (state, { isVisible }) => {
  return {
    ...state,
    isRequestModalVisible: isVisible,
  };
};

export const _addPayedWorker = (state, { customerId, workerId }) => {
  return {
    ...state,
    payedWorkers: [...state.payedWorkers, { customerId, workerId }],
  };
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_MODAL_DATA]: _setModalData,
  [Types.TOGGLE_REQUEST_MODAL]: _toggleRequestModal,
  [Types.ADD_PAYED_WORKER]: _addPayedWorker
});
