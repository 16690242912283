import React from 'react';
import { isArray } from 'lodash';
import { Suspense } from '~components/suspense';

import ReviewsListContent from './list-content';
import ReviewsSkeleton from './skeleton';

const ReviewsList = ({ reviews, isLoading, className, headline }) => {

  return (
    <Suspense fallback={<ReviewsSkeleton />} isLoading={isLoading}>
      {!reviews || !isArray(reviews) || reviews.length === 0 ? null : (
          <ReviewsListContent reviews={reviews} headline={headline} className={className} isLoading={isLoading} />
        )}
    </Suspense>
  );
};

export default ReviewsList;
