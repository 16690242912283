import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import * as REST from '~services/rest';
import { UserContext } from '~contexts/user';

const defaultState = {
  isLoading: false,
  favorites: [],
};

export const FavoriteContext = React.createContext(defaultState);

const fetchFavorites = async (personId, token) => {
  try {
    const response = await REST.get({
      name: `persons/${personId}/favorites`,
      requiresAuthentication: true,
      shouldThrowError: true,
      token,
    });
    return response.data || [];
  } catch (err) {
    return [];
  }
};

const postFavorite = async (currentUserId, idToFavorite, action, token) => {
  try {
    await REST.post({
      name: `persons/${currentUserId}/favorites`,
      requiresAuthentication: true,
      params: {
        favorite_id: idToFavorite,
        action
      },
      token
    });
    return true;
  } catch (err) {
    return null;
  }
};

const FavoritesProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const { getAccessTokenSilently } = useAuth0();

  const [isLoading, setIsLoading] = useState(defaultState.isLoading);
  const [favorites, setFavorites] = useState(defaultState.favorites);

  const getFavorites = async () => {
    setIsLoading(true);
    const token = await getAccessTokenSilently();
    const fav = await fetchFavorites(user.personId, token);
    setFavorites(fav);
    setIsLoading(false);
  };

  useEffect(async () => {
    if (!user || !user.personId) {
      return;
    }

    await getFavorites(user.personId);
  }, [user]);

  const favorite = async (idToFavorite) => {
    const token = await getAccessTokenSilently();
    await postFavorite(user.personId, idToFavorite, 'add', token);
    setFavorites([...favorites, idToFavorite]);
    return true;
  };

  const unfavorite = async (idToFavorite) => {
    const token = await getAccessTokenSilently();
    await postFavorite(user.personId, idToFavorite, 'remove', token);
    setFavorites(favorites.filter((f) => f !== idToFavorite));
    return true;
  };

  const state = {
    isLoading,
    favorites,
    favorite,
    unfavorite,
  }

  return <FavoriteContext.Provider value={state}>{children}</FavoriteContext.Provider>;
};

export default FavoritesProvider;
