import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  TwitterShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from 'react-share';
import { useDispatch } from 'react-redux';

import NotificationsActions from '~redux/notifications';
import { useStateFromProp } from '~hooks/common';
import { copyToClipboard } from '~helpers/common';

import { Input } from '~components/forms/input';
import Button from '~components/button';
import Modal from '~components/modal';

import './style.scss';

const Share = ({ sharedUrl = '', title = '', isOpened, onToggle = () => { } }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [isModalOpened, setModalOpen] = useStateFromProp(isOpened, onToggle);
  const [copyError, setCopyError] = useState(null);
  const inputRef = useRef();

  const handleCopy = () => {
    const copied = copyToClipboard(sharedUrl);
    if (!copied) {
      setCopyError(true);
    }

    setCopyError(false);
    inputRef.current.focus();
    dispatch(
      NotificationsActions.addNotification(
        intl.formatMessage({
          id: 'components.share.copied',
          defaultMessage: 'Copied!',
        })
      )
    );
  };

  return (
    <Modal.Wrapper isOpened={isModalOpened} onClose={() => setModalOpen()} className="c-modal--share">
      <Modal.Header>
        <h2 className="l-headline--secondary">
          {intl.formatMessage({
            id: 'components.share.headline',
            defaultMessage: 'Share',
          })}
        </h2>
      </Modal.Header>
      <Modal.Content>
        <div className="c-share__icons">
          <FacebookShareButton url={sharedUrl} title={title}>
            <FacebookIcon size={48} round />
            <span className="c-share__label">Facebook</span>
          </FacebookShareButton>
          <FacebookMessengerShareButton url={sharedUrl} title={title}>
            <FacebookMessengerIcon size={48} round />
            <span className="c-share__label">Messenger</span>
          </FacebookMessengerShareButton>
          <TwitterShareButton url={sharedUrl} title={title}>
            <TwitterIcon size={48} round />
            <span className="c-share__label">Twitter</span>
          </TwitterShareButton>
          <WhatsappShareButton url={sharedUrl} title={title}>
            <WhatsappIcon size={48} round />
            <span className="c-share__label">Whatsapp</span>
          </WhatsappShareButton>
          <LinkedinShareButton url={sharedUrl} title={title}>
            <LinkedinIcon size={48} round />
            <span className="c-share__label">LinkedIn</span>
          </LinkedinShareButton>
          <EmailShareButton url={sharedUrl} title={title} subject={title}>
            <EmailIcon size={48} round />
            <span className="c-share__label">Email</span>
          </EmailShareButton>
        </div>
        <div className="c-share__link">
          <Input value={sharedUrl} inputRef={inputRef} />
          <Button className="c-btn--text" onClick={handleCopy}>
            {intl.formatMessage({
              id: 'components.share.copy',
              defaultMessage: 'Copy',
            })}
          </Button>
        </div>
        {copyError && (
          <div className="c-share__error l-error">
            {intl.formatMessage({
              id: 'components.share.copy-error',
              defaultMessage: 'Could not copy to clipboard',
            })}
          </div>
        )}
      </Modal.Content>
      <Modal.Footer>
        <div className="c-modal__buttons--flex-end">
          <Button className="c-btn--secondary" onClick={() => onToggle(false)}>
            {intl.formatMessage({
              id: 'components.share.close',
              defaultMessage: 'Close',
            })}
          </Button>
        </div>
      </Modal.Footer>
    </Modal.Wrapper>
  );
};

export default Share;
