import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Elements } from '@stripe/react-stripe-js';

import { useLocationDetails } from '~hooks/useLocationDetails';

import { UserContext } from '~contexts/user';

import Loader from '~components/loader';
import Modal from '~components/modal';

import Address from './partials/address';
import Price from './partials/price';
import PaymentError from './partials/error';
import Stripe from './partials/stripe';

import usePrice from '../usePrice';
import useStripe from '../useStripe';

// import { STATIC_PAYMENT_PRICE } from '~src/constants'
// import { useWorkerLocationWithinWish } from '../useWorkerLocationWithinWish';

import './style.scss';

const Request = ({ onClose = () => {} }) => {
  const intl = useIntl();

  const { protocol, domain } = useSelector((state) => state.domainConfig);
  const { requestedContactId, locationId } = useSelector((state) => state.payment);

  const returnUrl = `${protocol}://${domain}/payment/process?contactId=${requestedContactId}`;
  const { user } = useContext(UserContext);
  const { locationDetails, isLoadingLocation, requestLocationByID } = useLocationDetails(locationId);
  const { price, isLoadingPrice, noWorkersError, paymentError, fetchPrice, setStaticPrice } = usePrice();
  const {
    stripePromise,
    clientSecret,
    checkoutSessionUrl,
    isLoading: isLoadingStripe,
    error: stripeError,
    appearance,
    fetchIntent,
    fetchCheckoutSessionUrl,
  } = useStripe();
  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  console.log(returnUrl);

  
  // const { isLoadingIsWorkerLocationWithinWish, isWorkerLocationWithinWish } = useWorkerLocationWithinWish(
  //   requestedContactId,
  //   locationDetails,
  //   price
  // );

  const handleLocationChange = useCallback((locationId) => {
    requestLocationByID(locationId);
  }, []);

  // maybe later we can do like from env it will manage if subscription or onetime
  const handlePriceSubmit = async () => {
    // stripe one time payment
    // await fetchIntent(user.personId, price.currency, price.amount, locationDetails.locationId, returnUrl);
    // stripe subscripetion payment
    await fetchCheckoutSessionUrl(user.personId, price.currency, price.amount, locationDetails.locationId, returnUrl);
  };

  useEffect(async () => {
    if (!requestedContactId) {
      setIsLoadingInitial(false);
      return;
    }

    // If No average price -> Fetch average price
    if (locationDetails && locationDetails.latitude && locationDetails.longitude) {
      await fetchPrice(locationDetails.latitude, locationDetails.longitude);
      // setStaticPrice(STATIC_PAYMENT_PRICE);
      setIsLoadingInitial(false);
    }
  }, [locationDetails, isLoadingLocation]);

  useEffect(() => {
    if (checkoutSessionUrl) {
      //console.log(checkoutSessionUrl);
      window.location.href = checkoutSessionUrl;
    }
  }, [checkoutSessionUrl]);

  const step = isLoadingInitial
    ? 'LOADING'
    : !requestedContactId
    ? 'INCORRECT_CONTACT_ID'
    : !locationDetails || !locationDetails.locationId || noWorkersError
    ? 'LOCATION'
    : paymentError
    ? 'PAYMENT_ERROR'
    : stripeError
    ? 'STRIPE_ERROR'
    : clientSecret
    ? 'STRIPE'
    : checkoutSessionUrl
    ? 'STRIPE_CHECKOUT_SESSION_REDIRECT'
    : 'PRICE';
    
  return (
    <Modal.Wrapper isOpened onClose={onClose} className="c-payment-request">
      {step === 'LOADING' ? (
        <Modal.Content>
          <Loader />
        </Modal.Content>
      ) : step === 'INCORRECT_CONTACT_ID' ? (
        <PaymentError onCancel={onClose}>
          {intl.formatMessage({
            id: 'containers.payment.request.modal.error.incorrect-contact-id',
            defaultMessage: 'We could not process payment for this worker',
          })}
        </PaymentError>
      ) : step === 'PAYMENT_ERROR' ? (
        <PaymentError
          onCancel={onClose}
          onRetry={() => fetchPrice(locationDetails.latitude, locationDetails.longitude)}
          // onRetry={() => setStaticPrice(STATIC_PAYMENT_PRICE)}
        >
          {intl.formatMessage({
            id: 'containers.payment.request.modal.error.payment-error',
            defaultMessage: 'We could not process your payment',
          })}
        </PaymentError>
      ) : step === 'STRIPE_ERROR' ? (
        <PaymentError onCancel={onClose} onRetry={handlePriceSubmit}>
          {intl.formatMessage({
            id: 'containers.payment.request.modal.error.stripe-error',
            defaultMessage: 'We could not process your payment',
          })}
        </PaymentError>
      ) : step === 'LOCATION' ? (
        <Address
          locationDetails={locationDetails}
          onSubmit={handleLocationChange}
          isLoading={isLoadingLocation}
          error={noWorkersError}
        />
      ) : step === 'PRICE' ? (
        <Price
          amount={price?.amount}
          currency={price?.currency}
          isLoading={isLoadingPrice || isLoadingStripe}
          onSubmit={handlePriceSubmit}
        />
      ) : step === 'STRIPE' ? (
        <Elements options={{ clientSecret, appearance }} stripe={stripePromise}>
          <Stripe returnUrl={returnUrl} />
        </Elements>
      ) : null}
    </Modal.Wrapper>
  );
};

export default Request;
