import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import { FiX } from 'react-icons/fi';

import './style.scss';

const Context = React.createContext();

const Wrapper = ({ children, isOpened = false, className, onClose, closableOnOverflowClick = true, ...props }) => {
  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = 'hidden';
      document.querySelector('#root').classList.add('l-blur');
    }
    return () => {
      const countModals = document.querySelectorAll('.c-modal__container').length;
      if (countModals === 0) {
        document.body.style.overflow = 'unset';
        document.querySelector('#root').classList.remove('l-blur');
      }
    };
  }, [isOpened]);

  const handleClose = React.useCallback(
    (e) => {
      e.preventDefault();
      onClose(!isOpened);
    },
    [isOpened, onClose]
  );

  return (
    isOpened &&
    ReactDOM.createPortal(
      <Context.Provider value={{ isOpened, onClose }}>
        <div {...props} className={cx('c-modal__container')} data-cy="c-modal__container">
          {closableOnOverflowClick && <div className="c-modal__overflow" onClick={handleClose} />}
          <div
            className={cx('c-modal', {
              [className]: !!className,
            })}
          >
            <button type="button" className={cx('c-modal__close')} onClick={handleClose}>
              <FiX />
            </button>
            {children}
          </div>
        </div>
      </Context.Provider>,
      document.body
    )
  );
};

const Header = ({ children, className, ...props }) => {
  return (
    <div
      {...props}
      className={cx('c-modal__header', {
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};

const Content = ({ children, className, ...props }) => {
  return (
    <div
      {...props}
      className={cx('c-modal__content', {
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};

const Footer = ({ children, className, ...props }) => {
  return (
    <div
      {...props}
      className={cx('c-modal__footer', {
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
};

const useModal = ({ isOpened = false }) => {
  const [isModalOpen, setModalOpen] = useState(isOpened);

  return [isModalOpen, setModalOpen];
};

export default {
  Wrapper,
  Header,
  Content,
  Footer,
  useModal,
};
