import React, { useState } from 'react';
import cx from 'classnames';
import { IoImageOutline } from 'react-icons/io5';
import { renderFirstName } from '~helpers/common';

import './style.scss';

const imgFallback = '/images/no-image.svg';

const Avatar = ({ className, profilePicture, firstName, size = 'thumbnail' }) => {
  const imgToRender =
    size === 'thumbnail' && profilePicture?.thumbnail
      ? profilePicture.thumbnail
      : size === 'large' && profilePicture?.large
      ? profilePicture.large
      : imgFallback;

  const [isError, setIsError] = useState(false);

  const addDefaultSrc = (ev) => {
    ev.target.src = imgFallback;
    setIsError(true);
  };

  const fName = renderFirstName(firstName);

  return (
    <div
      className={cx('c-avatar', {
        [className]: !!className,
        'c-avatar--letter': !profilePicture,
        'c-avatar--default': !profilePicture,
        'c-avatar--is-error': isError,
        'c-avatar--thumbnail': size === 'thumbnail',
        'c-avatar--large': size === 'large',
      })}
    >
      {profilePicture ? (
        <img
          src={`${imgToRender}${profilePicture?.updatedAt ? `?${profilePicture.updatedAt}` : ''}`}
          alt={fName}
          key={profilePicture?.updatedAt}
          onError={addDefaultSrc}
          className="notranslate"
          loading="lazy"
        />
      ) : (
        <IoImageOutline />
      )}
    </div>
  );
};

export default Avatar;
