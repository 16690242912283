import React from 'react';
import { useIntl } from 'react-intl';

import Modal from '~components/modal';
import Button from '~components/button';

const PaymentError = ({ children, onRetry, onCancel = () => {} }) => {
  const intl = useIntl();

  return (
    <>
      <Modal.Header />
      <Modal.Content>
        <div className="l-error l-mb2 l-center">{children}</div>
      </Modal.Content>
      <Modal.Footer>
        <div className="c-modal__buttons--flex-end">
          <Button className="c-modal__button c-btn--text" onClick={onCancel}>
            {intl.formatMessage({
              id: 'containers.payment.request.modal.error.cancel',
              defaultMessage: 'Cancel',
            })}
          </Button>
          {onRetry && (
            <Button className="c-modal__button c-btn" onClick={onRetry}>
              {intl.formatMessage({
                id: 'containers.payment.request.modal.error.retry',
                defaultMessage: 'Retry',
              })}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </>
  );
};

export default PaymentError;
