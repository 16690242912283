import React from 'react';
import { FiMessageCircle } from 'react-icons/fi';

const Toggle = ({ count = 0, onToggle = () => {} }) => {
  return (
    <button type="button" onClick={onToggle} className="c-context-menu__toggle">
      <FiMessageCircle className="c-context-menu__toggle__icon" />
      {count > 0 ? <div className="c-badge c-conversations__badge">{count > 9 ? '9+' : count}</div> : <></>}
    </button>
  );
};

export default Toggle;
