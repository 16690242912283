import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { SeoContext } from './context';

const Seo = ({ children }) => {
  const intl = useIntl();
  const location = useLocation();

  const [seoUpdated, setSeoUpdated] = useState(null);
  const [checkUpdate, setCheckUpdate] = useState(0);

  useEffect(() => {
    // if seo not updated 1second after route change, set the title to default
    if (seoUpdated !== location.pathname) {
      document.title = intl.formatMessage({
        id: 'default-seo.title',
      });
      setSeoUpdated(location.pathname);
    }
  }, [checkUpdate]);

  let timeout;

  useEffect(() => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      setCheckUpdate(checkUpdate + 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [location.pathname]);

  const handleSeoChange = ({ title }) => {
    if (title && typeof title === 'string') {
      document.title = title;
      setSeoUpdated(location.pathname);
    }
  };

  return (
    <SeoContext.Provider
      value={{
        onSeoChange: handleSeoChange,
      }}
    >
      {children}
    </SeoContext.Provider>
  );
};

export default Seo;
