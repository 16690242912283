import { combineReducers } from 'redux';

/* ------------- Assemble The Reducers ------------- */
const reducers = combineReducers({
  intake: require('./intake').reducer,
  notifications: require('./notifications').reducer,
  domainConfig: require('./domain-config').reducer,
  payment: require('./payment').reducer,
});

export default reducers;
