import axios from 'axios';

import PromptService from '~services/prompt';
import axiosProvider from '~services/axiosProvider';
import mockedRequests from '~src/mocked-requests';
import intlProvider from '~services/intlProvider';

const addCancel = (promise, source) => {
  const cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return {
    cancel,
    then(...args) {
      const output = promise.then(...args);
      output.cancel = cancel;

      return output;
    },

    catch(...args) {
      const output = promise.catch(...args);
      output.cancel = cancel;

      return output;
    },
  };
};

const showServerError = (error) => {
  if (typeof window === 'undefined') {
    return;
  }
  const intl = intlProvider.getInstance();
  if (intl && error) {
    PromptService.alert({
      title: intl.formatMessage({ id: 'prompts.server-error.title', defaultMessage: 'Server error' }),
      content: intl.formatMessage({
        id: 'prompts.server-error.content',
        defaultMessage: 'An unexpected server error occurred.',
      }),
    });
  }
};

const getInstance = (name, mockFileName, axiosInstance) => {
  if (process.env.NODE_ENV === 'development' && process.env.RAZZLE_USE_MIRAGE === 'true') {
    if ((mockFileName && mockedRequests.indexOf(mockFileName) >= 0) || mockedRequests.indexOf(name) >= 0) {
      console.log(`🚀  Using mock REST response for ${name}`);
      return axiosProvider.mockAxios;
    }
  }
  return axiosInstance || axiosProvider.publicAxios;
};

export const post = async ({
  name,
  params,
  axiosInstance,
  headers = {},
  data,
  shouldThrowError = false,
  mockFileName,
  token,
  fallback,
}) => {
  const source = axios.CancelToken.source();

  const requestHeaders = {
    ...headers,
  };

  const instance = getInstance(name, mockFileName, axiosInstance);

  if (token) {
    requestHeaders.Authorization = `Bearer ${token}`;
  }

  if (fallback) {
    return instance({
      method: 'post',
      url: name,
      instance,
      headers: requestHeaders,
      params,
      data,
      cancelToken: source.token,
    })
      .then(({ data }) => data)
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }

        if (shouldThrowError) {
          throw error;
        } else {
          showServerError(error);
        }
      });
  }

  return instance
    .post(name, JSON.stringify(params), {
      headers: requestHeaders,
      cancelToken: source.token,
    })
    .then(({ data }) => data)
    .catch((error) => {
      if (axios.isCancel(error)) {
        return;
      }

      if (shouldThrowError) {
        throw error;
      } else {
        showServerError(error);
      }
    });
};

export const put = async ({
  name,
  params,
  axiosInstance,
  headers = {},
  shouldThrowError = false,
  mockFileName,
  token,
}) => {
  const source = axios.CancelToken.source();

  const requestHeaders = {
    ...headers,
  };

  const instance = getInstance(name, mockFileName, axiosInstance);

  if (token) {
    requestHeaders.Authorization = `Bearer ${token}`;
  }
  return instance
    .put(name, JSON.stringify(params), {
      headers: requestHeaders,
      cancelToken: source.token,
    })
    .then(({ data }) => data)
    .catch((error) => {
      if (axios.isCancel(error)) {
        return;
      }

      if (shouldThrowError) {
        throw error;
      } else {
        showServerError(error);
      }
    });
};

export const patch = async ({
  name,
  params,
  axiosInstance,
  headers = {},
  shouldThrowError = false,
  mockFileName,
  token,
}) => {
  const source = axios.CancelToken.source();

  const requestHeaders = {
    ...headers,
  };

  const instance = getInstance(name, mockFileName, axiosInstance);

  if (token) {
    requestHeaders.Authorization = `Bearer ${token}`;
  }
  return instance
    .patch(name, JSON.stringify(params), {
      headers: requestHeaders,
      cancelToken: source.token,
    })
    .then(({ data }) => data)
    .catch((error) => {
      if (axios.isCancel(error)) {
        return;
      }

      if (shouldThrowError) {
        throw error;
      } else {
        showServerError(error);
      }
    });
};

export const del = async ({
  name,
  params,
  axiosInstance,
  headers = {},
  shouldThrowError = false,
  mockFileName,
  token,
}) => {
  const source = axios.CancelToken.source();
  const requestParams = { ...params };
  const requestHeaders = {
    ...headers,
  };

  const instance = getInstance(name, mockFileName, axiosInstance);

  if (token) {
    requestHeaders.Authorization = `Bearer ${token}`;
  }

  return instance
    .delete(name, {
      params: requestParams,
      headers: requestHeaders,
      cancelToken: source.token,
    })
    .then(({ data }) => data)
    .catch((error) => {
      if (axios.isCancel(error)) {
        return;
      }

      if (shouldThrowError) {
        throw error;
      } else {
        showServerError(error);
      }
    });
};

export const get = ({
  name,
  params = {},
  axiosInstance,
  headers = {},
  shouldThrowError = false,
  mockFileName,
  token,
}) => {
  const source = axios.CancelToken.source();
  const requestParams = { ...params };
  const requestHeaders = {
    ...headers,
  };

  const instance = getInstance(name, mockFileName, axiosInstance);
  if (mockFileName && instance.defaults.baseURL === '/api/http') {
    requestParams.mockFileName = mockFileName;
  }

  if (token) {
    requestHeaders.Authorization = `Bearer ${token}`;
  }

  return addCancel(
    instance
      .get(name, {
        params: requestParams,
        headers: requestHeaders,
        cancelToken: source.token,
      })
      .then(({ data }) => data)
      .catch((error) => {
        if (axios.isCancel(error)) {
          return;
        }

        if (shouldThrowError) {
          throw error;
        } else {
          showServerError(error);
          console.warn(`Error while fetching get request ${name}`, error);
        }
      }),
    source
  );
};
