import React from 'react';
import { Link } from 'react-router-dom';

import { useIntl } from 'react-intl';

import { useAuth0 } from "@auth0/auth0-react";

import Button from '~components/button';

const Finalize = ({ url }) => {
  const intl = useIntl();

  const { logout } = useAuth0();

  return (
    <div className="c-header__sign-in c-header__finalize">
      <Link to={url}>
        <Button className="c-btn--text">{intl.formatMessage({ id: 'components.header.finalize', defaultMessage: 'Finalize' })}</Button>
      </Link>
      <Button className="c-btn--text c-finalize-logout" onClick={logout}>
        {intl.formatMessage({ id: 'components.header.logout', defaultMessage: 'Logout' })}
      </Button>
    </div>
  );
};

export default Finalize;
