import React, { useState, useContext, useCallback } from 'react';
import { FiUser as UserIcon, FiLogIn, FiLogOut } from 'react-icons/fi';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cx from 'classnames';

import { matchJPPath } from '~helpers/common';
import { AuthContext } from '~contexts/auth';
import { UserContext } from '~contexts/user';

import Avatar from '~components/avatar/';
import Dropdown from '~components/dropdown/';

import { workerMenuItems, clientMenuItems } from '../menu-items';

const ScopedMenu = ({ menuItems, onItemClick = () => {} }) => {
  const intl = useIntl();
  const { pathname } = useLocation();

  return menuItems.map((item) => (
    <Link to={item.pathname} onClick={onItemClick} key={item.pathname} 
    className={cx('c-vertical-nav__item', {
      'c-vertical-nav__item--active':  matchJPPath(item.pathname, pathname),
    })}
    >
      {intl.formatMessage({ id: item.translation, defaultMessage: item.defaultMessage })}
    </Link>
  ));
};

const AccountMenu = () => {
  const intl = useIntl();

  const [visible, setVisible] = useState(false);
  const { isAuthenticated, signIn, signUp, signOut } = useContext(AuthContext);

  const { user, isLoading } = useContext(UserContext);
  const { accountType } = user;

  const menuItems =
    accountType === 'worker'
      ? workerMenuItems({ locationId: user.operations?.address.place_id })
      : clientMenuItems({ locationId: user.wish?.address.place_id });

  const handleLogout = useCallback(() => {
    setVisible(false);
    signOut();
  });

  const handleLogin = useCallback(() => {
    setVisible(false);
    signIn();
  });

  const handleRegister = useCallback(() => {
    setVisible(false);
    signUp();
  });

  const handleMenuItemClick = useCallback(() => {
    setVisible(false);
  });

  return (
    <div className="c-horizontal-nav__item c-header__navitem c-header__navitem--mobile c-context-menu__wrapper">
      <Dropdown.Container
        placement="bottom-end"
        visible={visible}
        onVisibleChange={setVisible}
        optionsClassName="c-context-menu c-header__context-menu"
      >
        <Dropdown.Toggle>
          <div onClick={() => setVisible(!visible)} className="c-context-menu__toggle">
            <div className="c-header__account-picture">
              {isAuthenticated && !isLoading ? (
                <Avatar firstName={user.firstName} profilePicture={user.profilePicture} />
              ) : (
                <UserIcon className="c-context-menu__toggle__icon" />
              )}
            </div>
            <span>{intl.formatMessage({ id: 'components.header.account.toggle', defaultMessage: 'Account' })}</span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Content>
          {isAuthenticated ? (
            <>
              <div className="c-vertical-nav c-context-menu__nav">
                <ScopedMenu menuItems={menuItems} onItemClick={handleMenuItemClick} />
              </div>
              <div className="c-vertical-nav c-context-menu__nav">
                <div className="c-vertical-nav__item c-logout-link" onClick={handleLogout}>
                  <FiLogOut className="c-context-menu__option__icon" />
                  {intl.formatMessage({ id: 'components.header.account.logout', defaultMessage: 'Logout' })}
                </div>
              </div>
            </>
          ) : null}

          {!isAuthenticated ? (
            <div className="c-vertical-nav c-context-menu__nav">
              <div className="c-vertical-nav__item" onClick={handleLogin}>
                <FiLogIn className="c-context-menu__option__icon" />
                {intl.formatMessage({ id: 'components.header.account.login', defaultMessage: 'Login' })}
              </div>
              <div className="c-vertical-nav__item" onClick={handleRegister}>
                <FiLogIn className="c-context-menu__option__icon" />
                {intl.formatMessage({ id: 'components.header.account.register', defaultMessage: 'Register' })}
              </div>
            </div>
          ) : null}
        </Dropdown.Content>
      </Dropdown.Container>
    </div>
  );
};

export default AccountMenu;
