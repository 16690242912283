import React from 'react';

// NOTE: This isnt a real Suspense, but it was introduced to ease the process of possible switch to Suspense in future
// For now we're using 'isLoading' prop, which will be removed with React Suspense

export const Suspense = ({ fallback = null, isLoading, children }) => {
  return isLoading ? fallback : children;
};

// Error boundaries currently have to be classes.
export class ErrorBoundary extends React.Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    };
  }

  render() {
    if (this.state.hasError || !this.props.children) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}
