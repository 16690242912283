import React from 'react';

import FullLoader from '~components/loader/full-loader';

const DeferReadiness = ({ children, isReady }) => {
  if (isReady) {
    return <>{children}</>;
  }

  return <FullLoader />;
};

export default DeferReadiness;
