import { useSelector } from 'react-redux';

const HOUR = 3600;

export const useDefaultWish = () => {
  const { translations } = useSelector((state) => state.domainConfig);
  const locale = translations.substr(0, translations.indexOf('_'));

  return {
    address: null,
    duration: 3 * HOUR, // 3 hours
    interval: 1, // weekly
    timeframes: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    languages: [locale],
    attributes: [],
    personalNote: '',
    published: true,
  };
};
