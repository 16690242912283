import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import LRU from 'lru-cache';
import { loadableReady } from '@loadable/component';
import { version } from '../package.json';
import * as Sentry from '@sentry/react';
import App from './app';
import { configureStore } from './store/configureStore';
import ServerContext from './serverContext';
import ScrollToTop from './routes/scroll-to-top';

const initClient = async () => {
  const store = await configureStore(window.__PRELOADED_STATE__);
  const { sentryDsn, appEnv, domain } = store.getState().domainConfig;

  const cache = new LRU();
  cache.load(window.__SERVER_DATA__ || {});

  console.log(`🚀 v${version}`);
  const history = createBrowserHistory();
  delete window.__PRELOADED_STATE__;
  delete window.__SERVER_DATA__;

  // Start Mirage mock server if in development and no Cypress
  if (process.env.NODE_ENV === 'development' && !window.Cypress && process.env.RAZZLE_USE_MIRAGE === 'true') {
    const { makeServer } = require('./mirage');
    makeServer({ environment: 'development' });
  }

  //Enable sentry only on schoonmaakster.nl
  if (sentryDsn && domain === 'schoonmaakster.nl') {
    Sentry.init({
      dsn: sentryDsn,
      environment: appEnv,
      release: version,
      integrations: [
        new Sentry.BrowserTracing({
          // See docs for support of different versions of variation of react router
          // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        }),
        new Sentry.Replay(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", "https://staging.huishoudelijke-hulp.jp.ict-works.info", "https://huishoudelijke-hulp.nl"],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  loadableReady(() => {
    hydrate(
      <Provider store={store}>
        <ServerContext.Provider value={cache}>
          <BrowserRouter>
            <ScrollToTop />
            <App />
          </BrowserRouter>
        </ServerContext.Provider>
      </Provider>,
      document.getElementById('root')
    );
  });

  if (module.hot) {
    module.hot.accept('./app', () => {
      loadableReady(() => {
        hydrate(
          <Provider store={store}>
            <ServerContext.Provider value={cache}>
              <BrowserRouter>
                <ScrollToTop />
                <App />
              </BrowserRouter>
            </ServerContext.Provider>
          </Provider>,
          document.getElementById('root')
        );
      });
    });
  }

  const swRegisteredEvent = new Event('swRegistered');

  // Get the user-agent string
  const userAgentString = navigator.userAgent;

  // Detect Chrome
  const chromeAgent = userAgentString.indexOf('Chrome') > -1;

  // Detect Safari
  let safariAgent = userAgentString.indexOf('Safari') > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  if ('serviceWorker' in navigator && !safariAgent) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/sw.js').then(
        function (registration) {
          window.dispatchEvent(swRegisteredEvent);
          window.swRegistered = true;
        },
        function (err) {
          console.log('ServiceWorker registration failed: ', err);
        }
      );
    });
  } else if ('serviceWorker' in navigator && safariAgent) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .getRegistrations()
        .then(function (registrations) {
          for (const registration of registrations) {
            registration.unregister();
          }
        })
        .catch(function (err) {
          console.log('Service Worker registration failed: ', err);
        });
    });
  }

  // expose store when run in Cypress
  if (window.Cypress) {
    window.store = store;
  }
}; 

initClient();
