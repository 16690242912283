import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { FiLock, FiAward, FiMessageCircle, FiRefreshCcw } from 'react-icons/fi';

import Loader from '~components/loader';
import Modal from '~components/modal';
import Button from '~components/button';

const Price = ({ isLoading, amount, currency, onSubmit = () => {} }) => {
  const intl = useIntl();
  const { websiteTitle } = useSelector((state) => state.domainConfig);

  return (
    <>
      <Modal.Header>
        <div className="l-center">
          <img alt={websiteTitle.toLowerCase()} src="/images/logo.svg" />
          <h2 className="l-headline--secondary">
            {intl.formatMessage({
              id: 'containers.payment.request.modal.total.header',
              defaultMessage: 'Your Price',
            })}
          </h2>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="c-payment-request__total">
          <div className="c-total__label">
            {intl.formatMessage({
              id: 'containers.payment.request.modal.total.label',
              defaultMessage: 'Total price',
            })}
          </div>
          <div className="c-total__value">
            {isLoading ? (
              <Loader />
            ) : amount ? (
              intl.formatMessage(
                {
                  id: 'containers.payment.request.modal.total.value',
                  defaultMessage: '{amount}',
                },
                {
                  amount: intl.formatNumber(amount / 100, {
                    style: 'currency',
                    currency,
                  }),
                }
              )
            ) : (
              '-'
            )}
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer className="c-payment-request__footer">
        <div className="c-payment-request__button">
          <Button
            onClick={onSubmit}
            disabled={!amount || isLoading}
            isLoading={isLoading}
            className="c-btn c-payment-request__button"
          >
            <FiLock />
            {intl.formatMessage({
              id: 'containers.payment.request.modal.button',
              defaultMessage: 'Checkout securely',
            })}
          </Button>
        </div>
        <div className="c-payment-request__features">
          <div className="c-payment-request__feature">
            <FiAward />
            {intl.formatMessage({
              id: 'containers.payment.request.modal.footer.feature.first',
              defaultMessage: 'Safe and secure payment',
            })}
          </div>
          <div className="c-payment-request__feature">
            <FiMessageCircle />
            {intl.formatMessage({
              id: 'containers.payment.request.modal.footer.feature.second',
              defaultMessage: 'Unlimited functionality',
            })}
          </div>
          <div className="c-payment-request__feature">
            <FiRefreshCcw />
            {intl.formatMessage({
              id: 'containers.payment.request.modal.footer.feature.third',
              defaultMessage: 'No recurring payment',
            })}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default Price;
