import React, { memo, useState, useCallback } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import cx from 'classnames';

import './style.scss';

const MainMenuItems = memo(({ onClick = () => {} }) => {
  const intl = useIntl();
  const { pathname } = useLocation();

  return (
    <div className="c-main-menu__nav">
      <div className="c-vertical-nav c-context-menu__nav">

        <Link
              className={cx('c-vertical-nav__item', {
                'c-vertical-nav__item--active': matchPath(`/intake/client`, { path: pathname, exact: true }),
              })}
              to={`/intake/client`}
              onClick={() => onClick(false)}
            >
              {intl.formatMessage({
                id: 'components.header.menu.client-intake',
                defaultMessage: 'Find your help',
              })}
              
            </Link>

        <FormattedMessage id="routes.cleaning-work">
          {(msg) => (
            <Link
              className={cx('c-vertical-nav__item', {
                'c-vertical-nav__item--active': matchPath(`/intake/worker`, { path: pathname, exact: true }),
              })}
              to={`/intake/worker`}
              onClick={() => onClick(false)}
            >
              {intl.formatMessage({
                id: 'components.header.menu.cleaning-work',
                defaultMessage: 'Cleaning work',
              })}
            </Link>
          )}
        </FormattedMessage>

        <FormattedMessage id="routes.faq">
          {(msg) => (
            <Link
              className={cx('c-vertical-nav__item', {
                'c-vertical-nav__item--active': matchPath(`/${msg}`, { path: pathname, exact: true }),
              })}
              to={`/${msg}`}
              onClick={() => onClick(false)}
            >
              {intl.formatMessage({
                id: 'components.header.menu.faq',
                defaultMessage: 'FAQ',
              })}
            </Link>
          )}
        </FormattedMessage>

        <FormattedMessage id="routes.contact">
          {(msg) => (
            <Link
              className={cx('c-vertical-nav__item', {
                'c-vertical-nav__item--active': matchPath(`/${msg}`, { path: pathname, exact: true }),
              })}
              to={`/${msg}`}
              onClick={() => onClick(false)}
            >
              {intl.formatMessage({
                id: 'components.header.menu.contact',
                defaultMessage: 'Contact',
              })}
            </Link>
          )}
        </FormattedMessage>

        <FormattedMessage id="routes.privacy-policy">
          {(msg) => (
            <Link
              className={cx('c-vertical-nav__item', {
                'c-vertical-nav__item--active': matchPath(`/${msg}`, { path: pathname, exact: true }),
              })}
              to={`/${msg}`}
              onClick={() => onClick(false)}
            >
              {intl.formatMessage({
                id: 'components.header.menu.privacy-policy',
                defaultMessage: 'Privacy policy',
              })}
            </Link>
          )}
        </FormattedMessage>
      </div>
    </div>
  );
});

const MainMenu = () => {
  const [visible, setVisible] = useState(false);

  const handleVisible = useCallback((isVisible) => {
    if (isVisible) {
      document.body.classList.add('l-noscroll');
    } else {
      document.body.classList.remove('l-noscroll');
    }
    setVisible(isVisible);
  });

  const handleOverlayClick = useCallback(() => {
    if (!visible) {
      return;
    }

    document.body.classList.remove('l-noscroll');
    setVisible(false);
  });

  return (
    <div className={cx('c-horizontal-nav__item c-header__navitem c-main-menu', { 'c-main-menu--opened': visible })}>
      <div className="c-overlay" onClick={handleOverlayClick} />
      <button
        type="button"
        id="main-menu-ham-button"
        aria-label="Main menu"
        className="c-main-menu__toggle hamburger-lines"
        onClick={() => handleVisible(!visible)}
      >
        <span className="line line1" />
        <span className="line line2" />
        <span className="line line3" />
      </button>
      <MainMenuItems onClick={handleVisible} />
    </div>
  );
};

export default MainMenu;
