import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { LocationSearch as LocationSearchComponent } from '~components/forms/location';
import Modal from '~components/modal';

import '../style.scss';

const Address = ({ locationDetails, error, onSubmit = () => {} }) => {
  const intl = useIntl();

  const { websiteTitle } = useSelector((state) => state.domainConfig);

  const handleAddressChange = (loc) => {
    if (!loc) {
      return;
    }
    onSubmit(loc.place_id);
  };

  return (
    <>
      <Modal.Header>
        <div className="l-center">
          <img alt={websiteTitle.toLowerCase()} src="/images/logo.svg" />
          <h2 className="l-headline--secondary">
            {intl.formatMessage({
              id: 'containers.payment.request.modal.address.header',
              defaultMessage: 'Your Address',
            })}
          </h2>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="c-payment-address__wrapper">
          <LocationSearchComponent
            // isDataLoading={isLoadingLocation}
            location={locationDetails}
            placeholder={intl.formatMessage({
              id: 'containers.payment.request.modal.address.label',
              defaultMessage: 'Address',
            })}
            className="c-subheader__location-search c-autocomplete--focus-disabled"
            onSubmit={(loc) => handleAddressChange(loc)}
          />
          <div className="c-payment-request_address-desc l-mb2">
            {intl.formatMessage({
              id: 'containers.payment.request.modal.address.description',
              defaultMessage:
                'You pay to be able to chat with all workers available for this address',
            })}
          </div>
        </div>
      </Modal.Content>
      {error && (
        <Modal.Footer>
          <div className="l-error l-mb2 l-center">
            {intl.formatMessage({
              id: 'containers.payment.request.modal.no-workers',
              defaultMessage:
                'We could not find any workers at this location. Please change you address and try again.',
            })}
          </div>
        </Modal.Footer>
      )}
    </>
  );
};

export default Address;
