/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prettier/prettier */
import React, { Fragment } from 'react';
import { isArray } from 'lodash';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { matchPath } from 'react-router-dom';

import { LANGUAGES } from '~src/constants';

export const capitalize = (s) => s[0].toUpperCase() + s.slice(1);

export const canUseDOM = () => {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
}

export const isVerified = (verification) => {
  if(!verification || !isArray(verification)) {
    return false;
  }
  return verification.filter((v) => v.isVerified === true || v.is_verified === true).length > 0;
};

export const getListingRedirect = (role, locationId) => {

  const loc = locationId ? `?locationId=${locationId}` : '';
  const redirect = role === 'client' ?
    `/workers${loc}` :
    `/customers${loc}`;
  return redirect;
}

export const getSelectableLanguages = () => {
  const intl = useIntl();
  const domainTranslations = useSelector((state) => state.domainConfig.translations).substr(0, 2);
  const domainLangs = useSelector((state) => state.domainConfig.languages) || [];

  const domainLanguages = [
    ...new Set([
      domainTranslations,
      ...domainLangs,
    ])
  ];

  const preferredLanguages = domainLanguages.map((l) => ({
    value: l,
    placement: 'top',
    label: intl.formatMessage({
      id: `languages.${l}`
    })
  }));

  const standardLanguages = LANGUAGES.map((l) => ({
    ...l,
    placement: 'bottom',
    label: intl.formatMessage({
      id: `languages.${l.value}`
    })
  })).filter((l) => domainLanguages.indexOf(l.value) < 0);

  return [...preferredLanguages, ...standardLanguages];
};

const NEW_LINE_REGEX = /(\r\n|\r|\n)/g;

export const nl2br = (text) => {
  if (typeof text !== 'string') {
    return text;
  }

  return text.trim().split(NEW_LINE_REGEX).map((line, index) => (
    <Fragment key={index}>
      {line}<br />
    </Fragment>
  ));
};

/**
* Returns rounded value to a given step, eg:
* round(2.74, 0.1) = 2.7
* round(2.74, 0.25) = 2.75
* round(2.74, 0.5) = 2.5
* round(2.74, 1.0) = 3.0
* */
export const round = (value, step = 1.0) => {
  const inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}


export const copyToClipboard = async (text) => {
  if (!navigator?.clipboard) {
    console.warn('Clipboard not supported');
    return false;
  }

  // Try to save to clipboard then save it in the state if worked
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    console.warn('Copy failed', error);
    return false;
  }
}

/**
* Returns the distance in kilometers between to coordinates
* */
export const getDistance = (lat1, lon1, lat2, lon2) => {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

/**
* Returns the distance in kilometers between to coordinates formatted
* to 0.5 for > 1km and 0.1 for <1km
* */
export const getDistanceFormatted = (lat1, lon1, lat2, lon2) => {
  if (!lat1 || !lon1 || !lat2 || !lon2) {
    return null;
  }

  const distance = getDistance(lat1, lon1, lat2, lon2);
  return distance > 1 ? round(distance, 0.5) : round(distance, 0.1);
};

// Returns arrays intersections
// Example usage:
// getIntersection([1, 2, 3], [2, 3, 4, 5]);               // [2, 3]
// getIntersection([1, 2, 3], [2, 3, 4, 5], [1, 3, 5]);    // [3]
export const getIntersection = (a, ...arr) => [...new Set(a)].filter(v => arr.every(b => b.includes(v)));


/**
 * Remove duplicated objects by prop using ES6 Map
 * Usage:
 * const uniqueById = uniqByProp("id");
 * const unifiedArray = uniqueById(arrayWithDuplicates);
 * */
export const uniqByProp = prop => arr =>
  Array.from(
    arr
      .reduce(
        (acc, item) => (
          item && item[prop] && acc.set(item[prop], item),
          acc
        ), // using map (preserves ordering)
        new Map()
      )
      .values()
  );

export const stringToJSON = (str) => {
    if(!str) {
      return null;
    }

    try {
          return JSON.parse(str);
    } catch (e) {
          return null;
    }
  }

export const removeProperty = (propKey, { [propKey]: propValue, ...rest }) => rest;

  // Usage: object = removeProperties(object, 'a', 'b') // result => { c: 3 }
export const removeProperties = (object, ...keys) =>
    keys.length ? removeProperties(removeProperty(keys.pop(), object), ...keys) : object;


export const renderFirstName = (firstName) => {
  if(firstName && firstName === '[account-deleted]') {
    const intl = useIntl();
    return intl.formatMessage({
      id: `account-deleted`,
      defaultMessage: '[Account deleted]',
    })
  }
  return firstName;
}

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const hasWishOrOperations = (u) => !!u?.wish?.address?.place_id || !!u?.operations?.address?.place_id;

export const matchJPPath = (item, pathname) => {

  if(item.indexOf('/chats') >= 0 && pathname.indexOf('/discussion') >= 0) {
    return true;
  }
  
  return pathname !== '/' && matchPath(
    item.substring(
      0,
      item.indexOf('?') > 0 ? item.indexOf('?') : item.length
    ),
    { path: pathname, exact: false, strict: false }
  );
}