import React, { useEffect, useState, useContext, useMemo } from 'react'
import { FiHeart } from 'react-icons/fi'
import cx from 'classnames'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'

import { AuthContext } from '~contexts/auth'
import { useFavorite } from '~hooks/useFavorite'
import IntakeActions from '~redux/intake'

import Button from '~components/button'

import './style.scss'

const Favorite = ({
  personId,
  accountType = 'worker',
  onChange = () => {},
  className,
  isEditable = true,
  mode = 'button',
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const { isAuthenticated, signIn } = useContext(AuthContext)
  const { favorites, favorite, unfavorite } = useFavorite()

  const [isFav, setIsFav] = useState(favorites.some((f) => f === personId))
  const [isSubmitting, setIsSubmitting] = useState(false)

  const routePrefix = useMemo(() =>
    accountType === 'worker'
      ? 'worker'
      : 'customer',
    [accountType]
  )

  useEffect(() => {
    favorites.some((f) => f === personId) ? setIsFav(true) : setIsFav(false)
  }, [favorites])

  const handleFavoriteClick = async () => {
    if (isSubmitting) {
      return
    }

    if (!isAuthenticated) {
      dispatch(IntakeActions.setReferrer(`/${routePrefix}/${personId}`))
      signIn()
      return
    }

    setIsSubmitting(true)
    if (isFav) {
      await unfavorite(personId)
    } else {
      await favorite(personId)
    }
    setIsSubmitting(false)
    onChange(!isFav)
  }

  return mode === 'button' ? (
    // BUTTON VIEW
    <Button
      className={cx('c-favorite c-btn--secondary', {
        'c-favorite--enabled': isFav,
        [className]: !!className,
      })}
      onClick={() => (isEditable ? handleFavoriteClick() : undefined)}
      isLoading={isSubmitting}
    >
      <FiHeart
        className="c-favorite__heart"
        title={intl.formatMessage({ id: 'components.favorite.button', defaultMessage: 'Favorite' })}
      />
      <div className="c-favorite__content">
        {intl.formatMessage({ id: 'components.favorite.button', defaultMessage: 'Favorite' })}
      </div>
    </Button>
  ) : (
    // ICON VIEW
    <Button
      className={cx('c-favorite--icon', {
        'c-favorite--enabled': isFav,
        [className]: !!className,
      })}
      onClick={() => (isEditable ? handleFavoriteClick() : undefined)}
      isLoading={isSubmitting}
    >
      <FiHeart
        className="c-favorite__heart"
        title={intl.formatMessage({ id: 'components.favorite.button', defaultMessage: 'Favorite' })}
      />
    </Button>
  )
}

export default Favorite
