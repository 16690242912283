import React from 'react';

import Cities from './cities';
import Copyright from './copyright';
import Socials from './socials';

import './style.scss';

const Footer = ({ displayCities = false, displayNeighborhoods = false }) => {
  return (
    <footer className="c-footer">
      {displayCities && <Socials />}
      {displayCities && <Cities displayNeighborhoods={displayNeighborhoods} />}
      <Copyright />
    </footer>
  );
};

export default Footer;
