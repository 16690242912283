export const base64toBlob = async (b64) => {
  return fetch(b64).then((res) => res.blob());
};

export const loadImgFromUrl = async (imgSrc) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.crossOrigin = 'anonymous';
    img.referrerPolicy = 'no-referrer';
    img.onerror = reject;
    img.src = `${imgSrc}&no-cache-${Math.floor(Math.random() * 100000) + 1}`;
  });
};

export const imgToDataURL = (img) => {
  const canvas = document.createElement('canvas');
  const ctxMain = canvas.getContext('2d');
  canvas.width = img.width;
  canvas.height = img.height;
  ctxMain.drawImage(img, 0, 0, canvas.width, canvas.height);
  return ctxMain.canvas.toDataURL('image/webp');
};

export const imgToBlob = async (img) => {
  const canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  return new Promise((resolve, reject) => {
    const ctxMain = canvas.getContext('2d');
    ctxMain.drawImage(img, 0, 0, canvas.width, canvas.height);
    ctxMain.canvas.toBlob(async (blob) => {
      resolve(blob);
    }, 'image/webp');
  });
};

export const createThumbnail = async (b64, width, height) => {
  const img = await loadImgFromUrl(b64);
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return new Promise((resolve, reject) => {
    const ctxMain = canvas.getContext('2d');
    ctxMain.drawImage(img, 0, 0, canvas.width, canvas.height);
    ctxMain.canvas.toBlob(async (blob) => {
      resolve(blob);
    }, 'image/webp');
  });
};

/**
 * Returns user picture object for given person id
 * @param {String} personId - The personId of the user
 * @return {object}
 * {
 *    large: uri
 *    thumbnail: uri
 * }
 */
export const getUserPicture = (domain, personId) => {
  return {
    large: `${domain}public/${personId}-large.webp`,
    thumbnail: `${domain}public/${personId}-thumbnail.webp`,
    updatedAt: Date.now(),
  };
};
