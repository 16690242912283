import React, { useState, useContext } from 'react';
import cx from 'classnames';

import Header from '~components/header';
import Footer from '~components/footer';

import ServerContext from '~src/serverContext';

export const LayoutContext = React.createContext();

const LayoutProvider = ({ children }) => {
  const cache = useContext(ServerContext);
  const serverFooter = cache.get('footer');
  const serverCities = cache.get('serverPopularCities');

  const [displayCities, setDisplayCities] = useState(serverCities || false);
  const [displayFooter, setDisplayFooter] = useState(serverFooter ? serverFooter.displayFooter : true);
  const [displayNeighborhoods, setDisplayNeighborhoods] = useState(
    serverFooter ? serverFooter.displayNeighborhoods : false
  );
  const [displaySignIn, setDisplaySignIn] = useState(false);
  const [displayTopCTABar, setDisplayTopCTABar] = useState(true);
  const [logoToBottom, setLogoToBottom] = useState(true);

  const [locationId, setLocationId] = useState(serverFooter ? serverFooter.locationId : null);
  const [type, setType] = useState(serverFooter ? serverFooter.type : 'full');

  const handleLayoutChange = ({
    type = 'full',
    displayCities = false,
    displayNeighborhoods = false,
    displayFooter = true,
    displaySignIn = false,
    displayTopCTABar = false,
    logoToBottom = false,
    locationId = null,
  }) => {
    setType(type);
    setDisplayCities(displayCities);
    setDisplayNeighborhoods(displayNeighborhoods);
    setDisplayFooter(displayFooter);
    setDisplaySignIn(displaySignIn);
    setDisplayTopCTABar(displayTopCTABar);
    setLogoToBottom(logoToBottom);
    setLocationId(locationId);
  };

  const state = {
    onLayoutChange: handleLayoutChange,
    layout: { type, displayCities, displayNeighborhoods, displayFooter, displaySignIn, displayTopCTABar, logoToBottom, locationId },
  };

  return (
    <LayoutContext.Provider value={state}>
      <Header />
      <section className={cx('c-content', { 'c-content--account': type === 'account' })}>{children}</section>
      {displayFooter && (
        <Footer displayCities={displayCities} displayNeighborhoods={displayNeighborhoods} />
      )}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
