import { useEffect, useContext } from 'react';

import { LayoutContext } from '~contexts/layout';

export const useLayout = (
  {
    type = 'full',
    displayCities = false,
    displayNeighborhoods = false,
    displayFooter = true,
    displaySignIn = false,
    displayTopCTABar = false,
    logoToBottom = false,
    locationId = null,
  },
  dependencies = []
) => {
  const context = useContext(LayoutContext);

  if (context === undefined) {
    throw new Error('Layout must be used within a LayoutProvider');
  }

  const { layout, onLayoutChange } = context;

  useEffect(() => {
    onLayoutChange({ type, displayCities, displayNeighborhoods, displayFooter, displaySignIn, displayTopCTABar, logoToBottom, locationId });
  }, dependencies);

  return layout;
};
