import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AuthContext } from '~contexts/auth';
import { isInternalRoute } from '~src/routes/internal-paths';

export const PusherContext = React.createContext();

const Pusher = ({ children }) => {
  const [isSwRegistered, setIsSwRegistered] = useState(false);
  const [subscribedPerson, setSubscribedPerson] = useState(null);
  const [beamsClient, setBeamsClient] = useState(null);
  const [isBeamsClientStarted, setIsBeamsClientStarted] = useState(null);
  const [isRegisteringUser, setIsRegisteringUser] = useState(false);
  const { pusherInstanceId, appEnv } = useSelector((state) => state.domainConfig);

  // https://developer.mozilla.org/en-US/docs/Web/API/Notification/permission
  // granted / denied / default
  const [notificationsPermission, setNotificationsPermission] = useState(
    typeof window !== 'undefined' && 'Notification' in window ? Notification.permission : null
  );

  const { user } = useContext(AuthContext);
  const userId =
    user && user['https://sr2.ca/claims/user_metadata'] ? user['https://sr2.ca/claims/user_metadata'].uuid : null;
  const location = useLocation();

  // beamsClient.start() activates pusher connection if notifications
  // permissions are granted OR shows the prompt if they are not granted
  const startPusher = (client = beamsClient) => {
    // exit if we are on public route
    if (!isInternalRoute(location.pathname)) {
      return;
    }

    client
      .start()
      .then(() => {
        setIsBeamsClientStarted(true);
        setNotificationsPermission(Notification?.permission);
        console.log('Pusher Beams SDK started');
      })
      .catch((e) => console.error('Pusher Beams start error', e));
  };

  const stopPusher = (client = beamsClient) => {
    client
      .stop()
      .then(() => {
        // setBeamsClient(client);
        setIsBeamsClientStarted(false);
        setNotificationsPermission(Notification?.permission);
        setBeamsClient(null);
        console.log('Pusher Beams SDK has been stopped');
      })
      .catch((e) => console.error('Pusher Beams stop error', e));
  };

  const initializePusher = (registration) => {
    // If notification object is not supported return without initializing
    // Currently Safari on iOS, IE, WebView Android
    if (typeof window === 'undefined' || typeof Notification === 'undefined' || !Notification in window) {
      return;
    }

    const PusherPushNotifications = require('@pusher/push-notifications-web');

    // Initialize pusher beams
    const client = new PusherPushNotifications.Client({
      instanceId: pusherInstanceId,
      serviceWorkerRegistration: registration,
    });
    setBeamsClient(client);

  };
  

  const addDebugInterests = () => {
    // dev
    if (appEnv === 'development') {
      console.log('Adding pusher beams device interest debug-development');
      beamsClient.addDeviceInterest('debug-development');
      return;
    }

    // staging
    if (appEnv === 'staging') {
      console.log('Adding pusher beams device interest debug-staging');
      beamsClient.addDeviceInterest('debug-staging');
      // return;
    }

    // production
    // we use pawelclient@wanat.me for debug
    // if (process.env.NODE_ENV === 'production' && userId === 'b1f28098-945d-463e-a9b1-524ffad489f1') {
    //   console.log('Adding pusher beams device interest debug-production');
    //   beamsClient.addDeviceInterest('debug-production');
    // }
  };

  useEffect(() => {
    if (!isSwRegistered) {
      return;
    }

    navigator.serviceWorker
      .getRegistration()
      .then(function (registration) {
        if (!beamsClient) {
          initializePusher(registration);
        }
        setIsRegisteringUser(true);
      })
      .catch((e) => console.error('Pusher registration error', e));
  }, [isSwRegistered]);

  useEffect(() => {
    const handler = (e) => {
      setIsSwRegistered(true);
    };

    if (window && window.swRegistered) {
      handler();
    }

    window.addEventListener('swRegistered', handler);
    return () => {
      window.removeEventListener('swRegistered', handler);
    };
  }, []);

  useEffect(() => {
    if (!isRegisteringUser || !beamsClient || !isBeamsClientStarted || !isSwRegistered || !userId) {
      return;
    }

    beamsClient.addDeviceInterest(userId);
    setSubscribedPerson(userId);
    addDebugInterests();
    setIsRegisteringUser(false);
  }, [isRegisteringUser, beamsClient, isBeamsClientStarted, userId, subscribedPerson]);

  return (
    <PusherContext.Provider
      value={{
        notificationsPermission,
        startPusher,
        stopPusher,
      }}
    >
      {children}
    </PusherContext.Provider>
  );
};

export default Pusher;
