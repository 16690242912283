import React from 'react';

import LoaderComponent from '~components/loader';

const Loader = () => (
  <div className="c-conversations__loader">
    <LoaderComponent />
  </div>
);

export default Loader;
