import React from 'react';
import { useIntl } from 'react-intl';

import { useSeo } from '~hooks/useSeo';
import Hero from '~components/hero';

const NotFound = ({ headline = '', content = '' }) => {
  const intl = useIntl();
  useSeo(headline);

  return (
    <div className="c-account__main l-container l-mt2">
      <Hero.Wrapper>
        <Hero.Title>
          {headline ||
            intl.formatMessage({
              id: 'components.not-found.headline',
            })}
        </Hero.Title>
        <Hero.Intro>
          {content ||
            intl.formatMessage({
              id: 'components.not-found.content',
            })}
        </Hero.Intro>
      </Hero.Wrapper>
    </div>
  );
};

export default NotFound;
