import moment from 'moment';

export const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const TIMEFRAMES = {
  START: {
    morning: '06:00',
    afternoon: '12:00',
    evening: '18:00',
  },

  END: {
    morning: '11:00',
    afternoon: '17:00',
    evening: '20:00',
  },
};

export const isInTimeFrame = (timeframe, startAtTime, endAtTime) => {
  const lower = moment(startAtTime, 'h:m').toDate() <= moment(TIMEFRAMES.END[timeframe], 'h:m').toDate();
  const upper = moment(endAtTime, 'h:m').toDate() >= moment(TIMEFRAMES.START[timeframe], 'h:m').toDate();

  return lower && upper;
};

export const getTimeFrames = (av = []) => {
  return av.reduce((acc, entry) => {
    const day = DAYS[entry.startAtDay - 1];

    acc[day] = {
      morning: isInTimeFrame('morning', entry.startAtTime, entry.endAtTime),
      afternoon: isInTimeFrame('afternoon', entry.startAtTime, entry.endAtTime),
      evening: isInTimeFrame('evening', entry.startAtTime, entry.endAtTime),
    };

    return acc;
  }, {});
};

const getStartEndForDay = (day = {}, index) => {
  const result = {};

  Object.keys(day).forEach((key) => {
    if (day[key] && !result.startAtTime) result.startAtTime = TIMEFRAMES.START[key];
    if (day[key]) result.endAtTime = TIMEFRAMES.END[key];
  });

  if (!result.startAtTime) return null;
  result.startAtDay = index + 1;
  result.endAtDay = index + 1;
  return result;
};

export const getTimeFramesFromCheckboxes = (allFrames) => {
  if (!allFrames) {
    return;
  }
  let result = [];
  allFrames.forEach((frame, index) => {
    const day = getStartEndForDay(frame, index);
    if (day) result = [...result, day];
  });
  return result;
};

const UNIT_MEASURES = [
  { name: 'year', value: 31557600 },
  { name: 'month', value: 2629800 },
  { name: 'week', value: 604800 },
  { name: 'day', value: 86400 },
  { name: 'hour', value: 3600 },
  { name: 'minute', value: 60 },
  { name: 'second', value: 0 },
];

export const getTimeUnit = (v1, v2) => {
  const seconds = moment(v1).diff(v2, 'seconds');
  if (typeof seconds !== 'number') {
    return 'second';
  }
  return UNIT_MEASURES.find((u) => u.value <= Math.abs(seconds)).name;
};

/**
 * Get relative time with unit to easy render it with intl
 * @Usage
 * const relativeTime = getRelativeTime(dateStart, dateEnd);
 * @Params
 * - v1: starting date
 * - v2: ending date
 * @Returns Object with properties:
 * - unit: string unit of the relative time difference
 * - value: number of time difference in this unit
 * */
export const getRelativeTime = (v1, v2) => {
  const seconds = v1 - v2;

  if (typeof seconds !== 'number') {
    return { unit: 'second', value: 0 };
  }

  const measure = UNIT_MEASURES.find((u) => u.value <= Math.abs(seconds));
  const value = measure.name === 'second' ? seconds : parseInt(seconds / measure.value, 10);
  return { unit: measure.name, value };
};
