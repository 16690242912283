export const LANGUAGES = [
  {
    value: 'ab',
    placement: 'bottom',
  },
  {
    value: 'aa',
    placement: 'bottom',
  },
  {
    value: 'af',
    placement: 'bottom',
  },
  {
    value: 'ak',
    placement: 'bottom',
  },
  {
    value: 'sq',
    placement: 'bottom',
  },
  {
    value: 'am',
    placement: 'bottom',
  },
  {
    value: 'ar',
    placement: 'bottom',
  },
  {
    value: 'an',
    placement: 'bottom',
  },
  {
    value: 'hy',
    placement: 'bottom',
  },
  {
    value: 'as',
    placement: 'bottom',
  },
  {
    value: 'av',
    placement: 'bottom',
  },
  {
    value: 'ae',
    placement: 'bottom',
  },
  {
    value: 'ay',
    placement: 'bottom',
  },
  {
    value: 'az',
    placement: 'bottom',
  },
  {
    value: 'bm',
    placement: 'bottom',
  },
  {
    value: 'ba',
    placement: 'bottom',
  },
  {
    value: 'eu',
    placement: 'bottom',
  },
  {
    value: 'be',
    placement: 'bottom',
  },
  {
    value: 'bn',
    placement: 'bottom',
  },
  {
    value: 'bh',
    placement: 'bottom',
  },
  {
    value: 'bi',
    placement: 'bottom',
  },
  {
    value: 'bs',
    placement: 'bottom',
  },
  {
    value: 'br',
    placement: 'bottom',
  },
  {
    value: 'bg',
    placement: 'bottom',
  },
  {
    value: 'my',
    placement: 'bottom',
  },
  {
    value: 'ca',
    placement: 'bottom',
  },
  {
    value: 'ch',
    placement: 'bottom',
  },
  {
    value: 'ce',
    placement: 'bottom',
  },
  {
    value: 'ny',
    placement: 'bottom',
  },
  {
    value: 'zh',
    placement: 'bottom',
  },
  {
    value: 'cv',
    placement: 'bottom',
  },
  {
    value: 'kw',
    placement: 'bottom',
  },
  {
    value: 'co',
    placement: 'bottom',
  },
  {
    value: 'cr',
    placement: 'bottom',
  },
  {
    value: 'hr',
    placement: 'bottom',
  },
  {
    value: 'cs',
    placement: 'bottom',
  },
  {
    value: 'da',
    placement: 'bottom',
  },
  {
    value: 'dv',
    placement: 'bottom',
  },
  {
    value: 'nl',
    placement: 'bottom',
  },
  {
    value: 'en',
    placement: 'top',
  },
  {
    value: 'eo',
    placement: 'bottom',
  },
  {
    value: 'et',
    placement: 'bottom',
  },
  {
    value: 'ee',
    placement: 'bottom',
  },
  {
    value: 'fo',
    placement: 'bottom',
  },
  {
    value: 'fj',
    placement: 'bottom',
  },
  {
    value: 'fi',
    placement: 'bottom',
  },
  {
    value: 'fr',
    placement: 'top',
  },
  {
    value: 'ff',
    placement: 'bottom',
  },
  {
    value: 'gl',
    placement: 'bottom',
  },
  {
    value: 'ka',
    placement: 'bottom',
  },
  {
    value: 'de',
    placement: 'top',
  },
  {
    value: 'el',
    placement: 'bottom',
  },
  {
    value: 'gn',
    placement: 'bottom',
  },
  {
    value: 'gu',
    placement: 'bottom',
  },
  {
    value: 'ht',
    placement: 'bottom',
  },
  {
    value: 'ha',
    placement: 'bottom',
  },
  {
    value: 'he',
    placement: 'bottom',
  },
  {
    value: 'hz',
    placement: 'bottom',
  },
  {
    value: 'hi',
    placement: 'bottom',
  },
  {
    value: 'ho',
    placement: 'bottom',
  },
  {
    value: 'hu',
    placement: 'bottom',
  },
  {
    value: 'ia',
    placement: 'bottom',
  },
  {
    value: 'id',
    placement: 'bottom',
  },
  {
    value: 'ie',
    placement: 'bottom',
  },
  {
    value: 'ga',
    placement: 'bottom',
  },
  {
    value: 'ig',
    placement: 'bottom',
  },
  {
    value: 'ik',
    placement: 'bottom',
  },
  {
    value: 'io',
    placement: 'bottom',
  },
  {
    value: 'is',
    placement: 'bottom',
  },
  {
    value: 'it',
    placement: 'top',
  },
  {
    value: 'iu',
    placement: 'bottom',
  },
  {
    value: 'ja',
    placement: 'bottom',
  },
  {
    value: 'jv',
    placement: 'bottom',
  },
  {
    value: 'kl',
    placement: 'bottom',
  },
  {
    value: 'kn',
    placement: 'bottom',
  },
  {
    value: 'kr',
    placement: 'bottom',
  },
  {
    value: 'ks',
    placement: 'bottom',
  },
  {
    value: 'kk',
    placement: 'bottom',
  },
  {
    value: 'km',
    placement: 'bottom',
  },
  {
    value: 'ki',
    placement: 'bottom',
  },
  {
    value: 'rw',
    placement: 'bottom',
  },
  {
    value: 'ky',
    placement: 'bottom',
  },
  {
    value: 'kv',
    placement: 'bottom',
  },
  {
    value: 'kg',
    placement: 'bottom',
  },
  {
    value: 'ko',
    placement: 'bottom',
  },
  {
    value: 'ku',
    placement: 'bottom',
  },
  {
    value: 'kj',
    placement: 'bottom',
  },
  {
    value: 'la',
    placement: 'bottom',
  },
  {
    value: 'lb',
    placement: 'bottom',
  },
  {
    value: 'lg',
    placement: 'bottom',
  },
  {
    value: 'li',
    placement: 'bottom',
  },
  {
    value: 'ln',
    placement: 'bottom',
  },
  {
    value: 'lo',
    placement: 'bottom',
  },
  {
    value: 'lt',
    placement: 'bottom',
  },
  {
    value: 'lu',
    placement: 'bottom',
  },
  {
    value: 'lv',
    placement: 'bottom',
  },
  {
    value: 'gv',
    placement: 'bottom',
  },
  {
    value: 'mk',
    placement: 'bottom',
  },
  {
    value: 'mg',
    placement: 'bottom',
  },
  {
    value: 'ms',
    placement: 'bottom',
  },
  {
    value: 'ml',
    placement: 'bottom',
  },
  {
    value: 'mt',
    placement: 'bottom',
  },
  {
    value: 'mi',
    placement: 'bottom',
  },
  {
    value: 'mr',
    placement: 'bottom',
  },
  {
    value: 'mh',
    placement: 'bottom',
  },
  {
    value: 'mn',
    placement: 'bottom',
  },
  {
    value: 'na',
    placement: 'bottom',
  },
  {
    value: 'nv',
    placement: 'bottom',
  },
  {
    value: 'nb',
    placement: 'bottom',
  },
  {
    value: 'nd',
    placement: 'bottom',
  },
  {
    value: 'ne',
    placement: 'bottom',
  },
  {
    value: 'ng',
    placement: 'bottom',
  },
  {
    value: 'nn',
    placement: 'bottom',
  },
  {
    value: 'no',
    placement: 'bottom',
  },
  {
    value: 'ii',
    placement: 'bottom',
  },
  {
    value: 'nr',
    placement: 'bottom',
  },
  {
    value: 'oc',
    placement: 'bottom',
  },
  {
    value: 'oj',
    placement: 'bottom',
  },
  {
    value: 'cu',
    placement: 'bottom',
  },
  {
    value: 'om',
    placement: 'bottom',
  },
  {
    value: 'or',
    placement: 'bottom',
  },
  {
    value: 'os',
    placement: 'bottom',
  },
  {
    value: 'pa',
    placement: 'bottom',
  },
  {
    value: 'pi',
    placement: 'bottom',
  },
  {
    value: 'fa',
    placement: 'bottom',
  },
  {
    value: 'pl',
    placement: 'bottom',
  },
  {
    value: 'ps',
    placement: 'bottom',
  },
  {
    value: 'pt',
    placement: 'bottom',
  },
  {
    value: 'qu',
    placement: 'bottom',
  },
  {
    value: 'rm',
    placement: 'bottom',
  },
  {
    value: 'rn',
    placement: 'bottom',
  },
  {
    value: 'ro',
    placement: 'bottom',
  },
  {
    value: 'ru',
    placement: 'bottom',
  },
  {
    value: 'sa',
    placement: 'bottom',
  },
  {
    value: 'sc',
    placement: 'bottom',
  },
  {
    value: 'sd',
    placement: 'bottom',
  },
  {
    value: 'se',
    placement: 'bottom',
  },
  {
    value: 'sm',
    placement: 'bottom',
  },
  {
    value: 'sg',
    placement: 'bottom',
  },
  {
    value: 'sr',
    placement: 'bottom',
  },
  {
    value: 'gd',
    placement: 'bottom',
  },
  {
    value: 'sn',
    placement: 'bottom',
  },
  {
    value: 'si',
    placement: 'bottom',
  },
  {
    value: 'sk',
    placement: 'bottom',
  },
  {
    value: 'sl',
    placement: 'bottom',
  },
  {
    value: 'so',
    placement: 'bottom',
  },
  {
    value: 'st',
    placement: 'bottom',
  },
  {
    value: 'es',
    placement: 'top',
  },
  {
    value: 'su',
    placement: 'bottom',
  },
  {
    value: 'sw',
    placement: 'bottom',
  },
  {
    value: 'ss',
    placement: 'bottom',
  },
  {
    value: 'sv',
    placement: 'bottom',
  },
  {
    value: 'ta',
    placement: 'bottom',
  },
  {
    value: 'te',
    placement: 'bottom',
  },
  {
    value: 'tg',
    placement: 'bottom',
  },
  {
    value: 'th',
    placement: 'bottom',
  },
  {
    value: 'ti',
    placement: 'bottom',
  },
  {
    value: 'bo',
    placement: 'bottom',
  },
  {
    value: 'tk',
    placement: 'bottom',
  },
  {
    value: 'tl',
    placement: 'bottom',
  },
  {
    value: 'tn',
    placement: 'bottom',
  },
  {
    value: 'to',
    placement: 'bottom',
  },
  {
    value: 'tr',
    placement: 'bottom',
  },
  {
    value: 'ts',
    placement: 'bottom',
  },
  {
    value: 'tt',
    placement: 'bottom',
  },
  {
    value: 'tw',
    placement: 'bottom',
  },
  {
    value: 'ty',
    placement: 'bottom',
  },
  {
    value: 'ug',
    placement: 'bottom',
  },
  {
    value: 'uk',
    placement: 'bottom',
  },
  {
    value: 'ur',
    placement: 'bottom',
  },
  {
    value: 'uz',
    placement: 'bottom',
  },
  {
    value: 've',
    placement: 'bottom',
  },
  {
    value: 'vi',
    placement: 'bottom',
  },
  {
    value: 'vo',
    placement: 'bottom',
  },
  {
    value: 'wa',
    placement: 'bottom',
  },
  {
    value: 'cy',
    placement: 'bottom',
  },
  {
    value: 'wo',
    placement: 'bottom',
  },
  {
    value: 'fy',
    placement: 'bottom',
  },
  {
    value: 'xh',
    placement: 'bottom',
  },
  {
    value: 'yi',
    placement: 'bottom',
  },
  {
    value: 'yo',
    placement: 'bottom',
  },
  {
    value: 'za',
    placement: 'bottom',
  },
];
