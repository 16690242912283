import React from 'react'
import { useIntl } from 'react-intl';

import './style.scss';

const TopCTABar = () => {
    const intl = useIntl();

  return (
    <div className="c-top-cta-bar">
        {intl.formatMessage({ id: 'components.header.top-cta-bar.content', defaultMessage: 'Unlock unlimited cleaner contact for only €10!' })}
    </div>
  )
}

export default TopCTABar