import React from 'react';
import { SkeletonSimple } from '~components/skeleton';

import './style.scss';

const ProfileSkeleton = () => {
  return (
    <div className="c-profile c-profile--page">
      <div className="c-profile__picture">
        <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-profile-skeleton__image" />
      </div>

      <div className="c-profile__header">
        <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-profile-skeleton__firstName" />
        <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-profile-skeleton__score" />
        <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-profile-skeleton__price" />
        <div className="c-profile__top-buttons">
          <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-profile-skeleton__button" />
          <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-profile-skeleton__button" />
          <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-profile-skeleton__button" />
        </div>
      </div>
      <div className="c-profile__features">
        <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-profile-skeleton__features" />
      </div>
      <div className="c-profile__about">
        <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-profile-skeleton__about" />
        <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-profile-skeleton__about" />
      </div>
      <div className="c-profile__reviews">
        <div className="c-reviews-list">
          <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__review" />
          <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__review" />
          <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__review" />
        </div>
      </div>
    </div>
  );
};

export default ProfileSkeleton;
