import React, { useContext } from 'react';

import { useIntl } from 'react-intl';
import { PWAContext } from '~contexts/pwa';

import Button from '~components/button';

const InstallPWAButton = () => {
  const intl = useIntl();
  const { isStandalone, promptInstall, isInstallPromptSupported, isInstalled } = useContext(PWAContext);

  const handleStandaloneApp = async () => {
    const didInstall = await promptInstall();
    if (didInstall) {
      // User accepted PWA install
      console.log('PWA installed');
    }
  };

  if (isStandalone || isInstalled || !isInstallPromptSupported) {
    return null;
  }

  return (
    <Button className="c-btn--secondary c-header__pwa-button" onClick={handleStandaloneApp}>
      {intl.formatMessage({
        id: 'components.header.install-pwa-button',
        defaultMessage: 'App',
      })}
    </Button>
  );
};

export default InstallPWAButton;
