import React from 'react';

import { SkeletonSimple } from '~components/skeleton';

const ReviewsSkeleton = () => (
  <>
    <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__review" />
    <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__review" />
  </>
);

export default ReviewsSkeleton;
