import React from 'react';
import cx from 'classnames';

import './style.scss';

import DelayedRender from '~helpers/delayed-render';

const FullLoader = ({ className, delay = 100 }) => {
  return (
    <DelayedRender delay={delay}>
      <div
        className={cx('c-full-loader', {
          [className]: !!className,
        })}
      >
        <div className="c-full-loader__lds-default">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </DelayedRender>
  );
};

export default FullLoader;
