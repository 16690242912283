import React, { memo } from 'react';
import cx from 'classnames';

import { SkeletonSimple } from '~components/skeleton';

import './style.scss';

const CarouselSkeleton = memo(({ skeletonCount = 4, className }) => {
  return (
    <div className={cx('c-carousel c-carousel-skeleton', { [className]: !!className })}>
      {Array(skeletonCount)
        .fill()
        .map((item, index) => (
          <SkeletonSimple theme="primary" className="c-skeleton-simple__item c-skeleton-simple__card" key={index} />
        ))}
    </div>
  );
});

export default CarouselSkeleton;
