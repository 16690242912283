import * as yup from 'yup';

yup.addMethod(yup.mixed, 'addressValid', function () {
  return this.test({
    name: 'is-address-valid',
    exclusive: true,
    message: 'components.form.validation.string.address-valid',
    test: (value) => value && typeof value === 'object',
  });
});
