import React from 'react';
import { render } from 'react-dom';

import Prompt from '~components/prompt';

const hideModal = ({ onSubmit, onDismiss, container }) => {
  render(<Prompt onSubmit={onSubmit} onDismiss={onDismiss} isOpened={false} />, container);
  document.body.removeChild(container);
};

const generateModal = ({ title, content, ok = 'OK', cancel, callback = null }) => {
  const container = document.createElement('div');
  document.body.appendChild(container);

  const confirmation = new Promise((resolve) => {
    const onSubmit = (value = true) => resolve(value);
    const onDismiss = () => resolve(undefined);
    render(
      <Prompt title={title} content={content} ok={ok} cancel={cancel} isOpened onSubmit={onSubmit} onDismiss={onDismiss} callback={callback} />,
      container
    );
  });

  return confirmation.finally(() => {
    hideModal({ onSubmit: () => {}, onDismiss: () => {}, container });
  });
};

const confirm = ({ title, content, ok = 'OK', cancel = 'Cancel', callback }) => {
  return generateModal({ title, content, ok, cancel, callback });
};

const alert = ({ title = '', content = '', ok = 'Close', callback }) => {
  return generateModal({ title, content, ok, callback });
};

const error = (err, caption = 'Error occurred when trying to perform this action') => {
  return generateModal({
    title: 'Error',
    content: `${caption} - ${err.code ? `${err.code}: ` : ''}${err.message || ''}`,
    ok: 'Close',
  });
};

export default { confirm, alert, error };
