import { useIntl } from 'react-intl';

const useIntlTestimonials = () => {
  const intl = useIntl();

  return [
    {
      name: intl.formatMessage({
        id: 'containers.testimonials.reviews.first.name',
      }),
      date: intl.formatMessage({
        id: 'containers.testimonials.reviews.first.date',
      }),
      stars: parseFloat(
        intl.formatMessage({
          id: 'containers.testimonials.reviews.first.stars-value',
        })
      ),
      score: intl.formatMessage({
        id: 'containers.testimonials.reviews.first.score',
      }),
      content: intl.formatMessage({
        id: 'containers.testimonials.reviews.first.content',
      }),
    },
    {
      name: intl.formatMessage({
        id: 'containers.testimonials.reviews.second.name',
      }),
      date: intl.formatMessage({
        id: 'containers.testimonials.reviews.second.date',
      }),
      stars: parseFloat(
        intl.formatMessage({
          id: 'containers.testimonials.reviews.second.stars-value',
        })
      ),
      score: intl.formatMessage({
        id: 'containers.testimonials.reviews.second.score',
      }),
      content: intl.formatMessage({
        id: 'containers.testimonials.reviews.second.content',
      }),
    },
    {
      name: intl.formatMessage({
        id: 'containers.testimonials.reviews.third.name',
      }),
      date: intl.formatMessage({
        id: 'containers.testimonials.reviews.third.date',
      }),
      stars: parseFloat(
        intl.formatMessage({
          id: 'containers.testimonials.reviews.third.stars-value',
        })
      ),
      score: intl.formatMessage({
        id: 'containers.testimonials.reviews.third.score',
      }),
      content: intl.formatMessage({
        id: 'containers.testimonials.reviews.third.content',
      }),
    },
  ];
};

export default useIntlTestimonials;
