import { useReducer } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import * as REST from '~services/rest';
import { DEFAULT_PRICE } from '~constants/index.js';

const usePrice = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [priceInfo, updatePriceInfo] = useReducer(
    (prev, action) => {
      switch (action.type) {
        case 'fetch':
          return {
            price: null,
            isLoadingPrice: true,
            noWorkersError: false,
            paymentError: false,
          };
        case 'success':
          return {
            price: action.price,
            isLoadingPrice: false,
            noWorkersError: false,
            paymentError: false,
          };
        case 'workersError':
          return {
            price: null,
            isLoadingPrice: false,
            noWorkersError: true,
            paymentError: false,
          };
        case 'paymentError':
          return {
            price: null,
            isLoadingPrice: false,
            noWorkersError: false,
            paymentError: true,
          };
        default:
          throw Error(`Unknown action: ${action.type}`);
      }
    },
    { price: null, isLoadingPrice: false, noWorkersError: false, paymentError: false }
  );

  const fetchPrice = async (latitude, longitude) => {
    try {
      updatePriceInfo({ type: 'fetch' });
      const token = await getAccessTokenSilently();
      const result = await REST.get({
        name: `payments`,
        requiresAuthentication: true,
        mockFileName: 'payments/get-average-amount',
        params: {
          latitude,
          longitude,
        },
        token,
      });

      if (result?.status_msg === 'Average amount not available for this customer!') {
        updatePriceInfo({ type: 'workersError' });
        return;
      }

      if (!result.data[0].average_amount) {
        updatePriceInfo({ type: 'paymentError' });
        return;
      }

      updatePriceInfo({
        type: 'success',
        price: { amount: result.data[0].average_amount, currency: result.data[0].currency || DEFAULT_PRICE.currency },
      });
    } catch (err) {
      console.log('Error when fetching payment amount', err);
      updatePriceInfo({ type: 'paymentError' });
    }
  };

  const setStaticPrice = ({amount, currency}) => {
      updatePriceInfo({
        type: 'success',
        price: { amount: amount, currency: currency },
      });
  };

  return { ...priceInfo, fetchPrice, setStaticPrice };
};

export default usePrice;
