import * as yup from 'yup';

yup.addMethod(yup.string, 'noEmailInContent', function () {
  return this.test({
    name: 'no-email-in-content',
    exclusive: true,
    message: 'components.form.validation.string.no-email-in-content',
    test: (value) => {
      if (!value) {
        return true;
      }

      const emailRegex = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

      return !emailRegex.test(value);
    },
  });
});
