import React, { useState, useEffect } from 'react';

const checkStandalone = () => {
  if (typeof navigator === 'undefined' || typeof window === 'undefined') return false;
  return navigator?.standalone || window?.matchMedia('(display-mode: standalone)').matches;
};

const initialState = {
  isInstallPromptSupported: false,
  promptInstall: () => null,
  isStandalone: checkStandalone(),
  isInstalled: typeof window !== 'undefined' ? localStorage.getItem('pwaInstalled') === '1' : false,
};

export const PWAContext = React.createContext(initialState);

const PWA = ({ children }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const beforeinstallpromptHandler = (e) => {
      // Prevent install prompt from showing so we can prompt it later

      e.preventDefault();

      const promptInstall = async () => {
        const promptRes = await e.prompt();
        if (promptRes.outcome === 'accepted') {
          setState({
            ...state,
            isStandalone: checkStandalone(),
          });
          return true;
        }
        return false;
      };

      localStorage.setItem('pwaInstalled', '0');
      setState({
        isInstallPromptSupported: true, // reaching this handler means that install prompt is supported
        promptInstall,
        isStandalone: checkStandalone(),
        isInstalled: false,
      });
    };

    const onAppInstalled = () => {
      localStorage.setItem('pwaInstalled', '1');
      setTimeout(() => setState({ ...state, isInstalled: true, isStandalone: checkStandalone() }), 200);
    };

    const onMatchMedia = () => {
      localStorage.setItem('pwaInstalled', '1');
      setState({
        ...state,
        isInstalled: true,
        isStandalone: checkStandalone(),
      });
    };

    // Listen on the installation prompt. If this listener is triggered,
    // it means PWA install is possible.
    window.addEventListener('beforeinstallprompt', beforeinstallpromptHandler);

    // It's also possible to know when the user installed the app by
    // listening the app installed event
    window.addEventListener('appinstalled', onAppInstalled);

    // On Chrome, when user opens the previous installed app
    // from the website (via the shortcut in the address bar),
    // we want to check again if the app is in standalone mode.
    window.matchMedia('(display-mode: standalone)').addListener(onMatchMedia);

    return () => {
      // Cleanup event listeners
      window.removeEventListener('beforeinstallprompt', beforeinstallpromptHandler);
      window.removeEventListener('appinstalled', onAppInstalled);
      window.matchMedia('(display-mode: standalone)').removeEventListener('change', onMatchMedia);
    };
  }, [state]);

  return <PWAContext.Provider value={state}>{children}</PWAContext.Provider>;
};

export default PWA;
