import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

// import picture from '~public/images/cleaning-services.jpg';
// import pictureWebp from '~public/images/cleaning-services.jpg?format=webp';

import picture from '~public/images/juanpescador.com-domestic-care.jpg';
import pictureWebp from '~public/images/juanpescador.com-domestic-care.jpg?format=webp';

// import pictureMob from '~public/images/cleaning-services-mob.jpg';
// import pictureWebpMob from '~public/images/cleaning-services-mob.jpg?format=webp';

import pictureMob from '~public/images/juanpescador.com-cleaning-housekeeping.jpg';
import pictureWebpMob from '~public/images/juanpescador.com-cleaning-housekeeping.jpg?format=webp';

import './style.scss';

const Wrapper = ({ children, className }) => {
  const [hasImageLoaded, setHasImageLoaded] = useState(false);
  // const [isMobile, setIsMobile] = useState(false);

  const imgRef = useRef(null);

  const  onLoadImg = () => {
    setHasImageLoaded(true)
  }
  useEffect(() => {
    if (imgRef.current?.complete && !hasImageLoaded) {
      onLoadImg();
    }

    // const handleResize = () => {
    //   setIsMobile(window.innerWidth <= 599);
    // }; 

    // Set initial isMobile value
    // handleResize();

    // window.addEventListener('resize', handleResize);

    // return () => {
    //   window.removeEventListener('resize', handleResize);
    // };

   }, []);

  //  const displayStyle = hasImageLoaded && isMobile ? 'block' : 'none';
  //  style={{ display: displayStyle }}

  return (
    <div className={cx('c-hero-hp', { [className]: !!className })}>
      <div className="c-hero-hp__inner">
        {/* <div className={`c-hero-hp__background ${!hasImageLoaded && 'fallback-size'}`}> */}
        <div className={`c-hero-hp__background`}>
          <div className="c-hero-hp__gradient-overlay"></div>
          <picture className="c-hero-hp__img c-hero-hp__img__desk">
            <source srcSet={pictureWebp.srcSet} type="image/webp" />
            <img src={picture.src} srcSet={picture.srcSet} width="810" height="682" alt='Juan Pescador' />
          </picture>
          <picture className={`c-hero-hp__img c-hero-hp__img__mob`} >
            <source srcSet={pictureWebpMob.srcSet} type="image/webp" />
            <img  ref={imgRef} src={pictureMob.src} srcSet={pictureMob.srcSet} onLoad={onLoadImg} width="360" height="240" alt='Juan Pescador' />
           
          </picture>
        </div>
          <div className="c-hero-hp__content">{children}</div>
      </div>
    </div>
  );
};


const Title = ({ children, className }) => {
  return (
    <div className={cx('c-hero-hp__title', { [className]: !!className })}>
      <h1 className="l-headline--primary">{children}</h1>
    </div>
  );
};
const Subtitle = ({ children, className }) => {
  return <div className={cx('c-hero-hp__subtitle', { [className]: !!className })}>{children}</div>;
};

const Postcode = ({children}) => {
  return (
    <div className="c-hero-hp__srarch">
      <div className="search-filt">{children}</div>
    </div>
  );
};

const Buttons = ({ children }) => {
  return <div className="c-hero-hp__buttons">{children}</div>;
};

const Intro = ({ children }) => {
  return <div className="c-hero-hp__intro">{children}</div>;
};

export default {
  Wrapper,
  Title,
  Postcode,
  Buttons,
  Intro,
  Subtitle,
};
