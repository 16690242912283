import React from 'react';
import { FiLoader as LoaderIcon } from 'react-icons/fi';
import cx from 'classnames';

import './style.scss';

const Loader = ({ className }) => {
  return (
    <div
      className={cx('c-loader__wrapper', {
        [className]: !!className,
      })}
    >
      <LoaderIcon className="c-loader__icon" />
    </div>
  );
};

export default Loader;
